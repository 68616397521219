import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { DocumentsFormProps, DataType } from "../types";
import { MergedColumnType } from "../../../ui/table/utils/types";
import { request } from "../../../../services";
import { DrawerProvider } from "../../../../contexts/drawer/index.context";
import { TranslationContext } from "../../../../contexts/translation/index.context";
import { FormContext } from "../../../../contexts/form/index.context";
import { ModalContext } from "../../../../contexts/modal/index.context";
import { TableContext } from "../../../../contexts/table/index.context";
import { SessionContext } from "../../../../contexts/session/index.context";
import User from "../../../../services/user";
//Utils
import { setFieldValuesFunctions } from "../utils/set-field-values";
//Components
import Drawer from "../../../ui/drawer";
import Documents from "../../../documents";
import Directories from "../../../directories";
//UI
import Form from "antd/lib/form";
import Tabs from "antd/lib/tabs";
import Space from "antd/lib/space";
import Input from "antd/lib/input";
import DatePicker from "antd/lib/date-picker";
import Select from "antd/lib/select";
import Checkbox from "antd/lib/checkbox";
import type { TabsProps } from "antd/es/tabs";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

const { TextArea } = Input;

export default function DocumentsForm(props: DocumentsFormProps) {
  const location = useLocation();
  const currentUser = User.decode();
  const [loading, setLoading] = useState(false);
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { rowData } = useContext(TableContext);
  const { sessionData } = useContext(SessionContext);
  const { activeModal, disabled, setDisabled, setRefresh } =
    useContext(ModalContext);
  const edit = activeModal === "edit";
  const isOutcoming = location.pathname === "/documents/outcoming";
  const isInternal = location.pathname === "/documents/internal";
  const isRelatedDocuments = props.url === "/documents/related-documents";
  const dateFormatList = ["DD.MM.YYYY", "DD-MM-YYYY"];
  const [selectData, setSelectData] = useState<DataType[]>([]);
  const [correspondentData, setCorrespondentData] = useState<
    MergedColumnType[]
  >([]);
  const paths: { [key: string]: number } = {
    "/documents/incoming": 1,
    "/documents/outcoming": 2,
    "/documents/internal": 3,
  };

  const typeOfDocument = paths[location.pathname] || null;

  const selectOptions = selectData.map((item) => ({
    value: item.id,
    label: item.Name,
  }));

  const correspondentOptions = correspondentData.map((item) => ({
    value: item.id,
    label: item.Name,
  }));

  const filterOption = (
    input: string,
    option?: { label: string; value: number }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (activeModal === "edit" && sessionData) {
      const setFieldValues = setFieldValuesFunctions[location.pathname];
      setFieldValues && setFieldValues(form, sessionData);
    } else {
      form.resetFields();
    }
  }, [activeModal, sessionData]);

  const pathname = location.pathname.split("/").pop();
  const typeMap: { [key: string]: number } = {
    incoming: 1,
    outcoming: 2,
    internal: 3,
  };
  const typeId = typeMap[pathname || ""];

  const fetchSelectData = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getdataselect`,
        {
          method: "post",
          body: { id: typeId },
          cancelTokenKey: "getdataselect",
        }
      )
    )?.content;
    setSelectData(content || []);
  };

  const fetchCorrespondentData = async () => {
    const content = (
      await request(
        `${location.pathname
          .split("/")
          .slice(0, 2)
          .join("/")}/getcorrespondent`,
        {
          method: "get",
          cancelTokenKey: "getcorrespondent",
        }
      )
    )?.content;
    setCorrespondentData(content || []);
  };

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};

      if (rowData["/directories/structural-units"]) {
        fieldsValue.placeOfRegistration =
          rowData["/directories/structural-units"].id;
      }
      if (rowData["/directories/types-of-documents"]) {
        fieldsValue.typeOfDocument =
          rowData["/directories/types-of-documents"].id;
      }
      if (rowData["/directories/indexes-of-documents"]) {
        fieldsValue.documentIndex =
          rowData["/directories/indexes-of-documents"].id;
      }
      if (rowData["/directories/correspondents"]) {
        fieldsValue.correspondent = rowData["/directories/correspondents"].id;
      }
      if (rowData["/directories/employees"]) {
        fieldsValue.addressed = rowData["/directories/employees"].id;
      }
      if (rowData["/directories/types-of-deliveries"]) {
        fieldsValue.typeOfDelivery =
          rowData["/directories/types-of-deliveries"].id;
      }
      if (rowData["/directories/nomenclature-of-cases-current"]) {
        fieldsValue.case =
          rowData["/directories/nomenclature-of-cases-current"].id;
      }
      if (rowData["/directories/employees/signatory"]) {
        fieldsValue.signatory = rowData["/directories/employees/signatory"].id;
      }
      if (rowData["/directories/employees/author"]) {
        fieldsValue.author = rowData["/directories/employees/author"].id;
      }
      if (rowData["/directories/nomenclature-of-cases"]) {
        fieldsValue.case = rowData["/directories/nomenclature-of-cases"].id;
      }
      if (rowData["/directories/types-of-communication"]) {
        fieldsValue.typesOfCommunication =
          rowData["/directories/types-of-communication"].id;
      }
      if (rowData["/documents/list-of-documents"]) {
        fieldsValue.document = rowData["/documents/list-of-documents"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  let items: TabsProps["items"] = [
    {
      key: "1",
      label: intl("document"),
      children: (
        <DrawerProvider>
          {/* <Form.Item
            // {...fullLayout}
            // label={intl("registration-department")}
            name="registrationDepartment"
            // rules={[
            //   {
            //     required: true,
            //     message: intl("registration-department-required"),
            //   },
            // ]}
          >
            <Drawer>null</Drawer>
          </Form.Item> */}
          <Form.Item
            label={intl("place-of-registration")}
            name="placeOfRegistration"
          >
            <Drawer
              url="/directories/structural-units"
              name={edit ? sessionData.PlaseRegistration : undefined}
              name2={rowData["/directories/structural-units"]?.data.Name}
              setFormFields={setFormFields}
              fieldName={"placeOfRegistration"}
            >
              <Directories url="/directories/structural-units" />
            </Drawer>
          </Form.Item>
          <Form.Item label={intl("document-log")} name="documentLog">
            <Select
              labelInValue
              onChange={(value) => {
                form.setFieldsValue({ documentLog: value });
              }}
              onDropdownVisibleChange={fetchSelectData}
              options={selectOptions}
              allowClear
            />
          </Form.Item>
          <Form.Item label={intl("types-of-documents")} name="typeOfDocument">
            <Drawer
              url="/directories/types-of-documents"
              name={edit ? sessionData.typeDocName : undefined}
              name2={rowData["/directories/types-of-documents"]?.data.Name}
              setFormFields={setFormFields}
              fieldName={"typeOfDocument"}
            >
              <Directories
                url="/directories/types-of-documents"
                type={typeOfDocument}
              />
            </Drawer>
          </Form.Item>
          {!isInternal && (
            <Form.Item
              label={intl("indexes-of-documents")}
              name="documentIndex"
            >
              <Drawer
                url="/directories/indexes-of-documents"
                name={edit ? sessionData.referenceName : undefined}
                name2={rowData["/directories/indexes-of-documents"]?.data.Name}
                setFormFields={setFormFields}
                fieldName={"documentIndex"}
              >
                <Directories url="/directories/indexes-of-documents" />
              </Drawer>
            </Form.Item>
          )}
          <Space className={styles["space"]}>
            <Form.Item
              label={intl("num-document")}
              name="numDocument"
              rules={[
                {
                  required: true,
                  message: intl("num-document-required"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={intl("from")}
              name="fromRegisteredDocument"
              rules={[
                {
                  required: true,
                  message: intl("from-receipt"),
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item name="project" valuePropName="checked">
              <Checkbox>{intl("project")}</Checkbox>
            </Form.Item>
          </Space>
          {!(isOutcoming || isInternal) && (
            <Space className={styles["space"]}>
              <Form.Item label={intl("num-output")} name="numOutput">
                <Input />
              </Form.Item>
              <Form.Item label={intl("from")} name="fromRegisteredOutput">
                <DatePicker />
              </Form.Item>
            </Space>
          )}
          {!isInternal &&
            (!isOutcoming ? (
              <Form.Item label={intl("correspondent")} name="correspondent">
                <Drawer
                  url="/directories/correspondents"
                  name={edit ? sessionData.kontragentName : undefined}
                  name2={rowData["/directories/correspondents"]?.data.Name}
                  setFormFields={setFormFields}
                  fieldName={"correspondent"}
                >
                  <Directories url="/directories/correspondents" />
                </Drawer>
              </Form.Item>
            ) : (
              <Form.Item label={intl("correspondent")} name="correspondent">
                <Select
                  mode={/* edit ? undefined :  */ "multiple"}
                  onChange={(_, value) => {
                    form.setFieldsValue({
                      pages: Array.isArray(value)
                        ? value.map((v: any) => v.value)
                        : [value],
                    });
                  }}
                  onDropdownVisibleChange={fetchCorrespondentData}
                  filterOption={filterOption}
                  options={correspondentOptions}
                  allowClear
                />
              </Form.Item>
            ))}
          {!(isOutcoming || isInternal) && (
            <Form.Item
              label={intl("addressed")}
              name="addressed"
              rules={[
                {
                  required: true,
                  message: intl("addressed-required"),
                },
              ]}
            >
              <Drawer
                url="/directories/employees"
                name={edit ? sessionData.Recipient : undefined}
                name2={rowData["/directories/employees"]?.data.fio}
                setFormFields={setFormFields}
                fieldName={"addressed"}
              >
                <Directories url="/directories/employees" />
              </Drawer>
            </Form.Item>
          )}
          <Form.Item label={intl("content")} name="content">
            <TextArea rows={2} />
          </Form.Item>
          {!(isOutcoming || isInternal) ? (
            <Form.Item label={intl("control")} name="control">
              <DatePicker />
            </Form.Item>
          ) : (
            <>
              <Form.Item label={intl("author")} name="author">
                <Drawer
                  url="/directories/employees"
                  name={edit ? sessionData.Author : undefined}
                  name2={rowData["/directories/employees/author"]?.data.fio}
                  setFormFields={setFormFields}
                  fieldName={"author"}
                >
                  <Directories url="/directories/employees" name="author" />
                </Drawer>
              </Form.Item>
              <Form.Item label={intl("the-signatory")} name="signatory">
                <Drawer
                  url="/directories/employees"
                  name={edit ? sessionData.SignFIO : undefined}
                  name2={rowData["/directories/employees/signatory"]?.data.fio}
                  setFormFields={setFormFields}
                  fieldName={"signatory"}
                >
                  <Directories url="/directories/employees" name="signatory" />
                </Drawer>
              </Form.Item>
            </>
          )}
          {!isInternal && (
            <Form.Item
              label={intl("types-of-deliveries")}
              name="typeOfDelivery"
            >
              <Drawer
                url="/directories/types-of-deliveries"
                name={edit ? sessionData.typeSendName : undefined}
                name2={rowData["/directories/types-of-deliveries"]?.data.Name}
                setFormFields={setFormFields}
                fieldName={"typeOfDelivery"}
              >
                <Directories url="/directories/types-of-deliveries" />
              </Drawer>
            </Form.Item>
          )}

          <Form.Item label={intl("notes")} name="notes">
            <Input />
          </Form.Item>
          <Space className={styles["space"]}>
            {!isInternal && (
              <Form.Item label={intl("num-delivery")} name="numDelivery">
                <Input />
              </Form.Item>
            )}
            <Form.Item label={intl("recorder")} name="recorder">
              <Input defaultValue={currentUser?.login} disabled={true} />
            </Form.Item>
          </Space>
        </DrawerProvider>
      ),
    },
    {
      key: "2",
      label: intl("storage"),
      children: (
        <DrawerProvider>
          <Space className={styles["space"]}>
            <Form.Item label={intl("number-of-pages")} name="numberOfPages">
              <Input />
            </Form.Item>
            <Form.Item
              label={intl("number-of-applications")}
              name="numberOfApplications"
            >
              <Input />
            </Form.Item>
          </Space>
          <Form.Item label={intl("case")} name="case">
            <Drawer
              url="/directories/nomenclature-of-cases"
              name={edit ? sessionData.Name_OfficialDoc : undefined}
              name2={
                rowData["/directories/nomenclature-of-cases"]?.data
                  .Name_OfficialDoc
              }
              setFormFields={setFormFields}
              fieldName={"case"}
            >
              <Directories url="/directories/nomenclature-of-cases" />
            </Drawer>
          </Form.Item>
          <Space className={styles["space"]}>
            <Form.Item label={intl("shelf-life")} name="shelfLife">
              <Input />
            </Form.Item>
            <Form.Item
              label={intl("written-off-in-the-case")}
              name="writtenOffInTheCase"
            >
              <DatePicker />
            </Form.Item>
          </Space>
        </DrawerProvider>
      ),
    },
    // {
    //   key: "3",
    //   label: intl("additional-information"),
    //   // children: 'Content of Tab Pane 3',
    // },
  ];

  const relatedDocumentsFormItems = (
    <>
      <Form.Item
        label={intl("types-of-communication")}
        name="typesOfCommunication"
      >
        <Drawer
          url="/directories/types-of-communication"
          name={edit ? sessionData.name_TypeDoc_relation : undefined}
          name2={rowData["/directories/types-of-communication"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"typesOfCommunication"}
        >
          <Directories url="/directories/types-of-communication" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("document-type")} name="documentType">
        <Select
          labelInValue
          onChange={(value) => {
            form.setFieldsValue({ documentType: value });
          }}
          onDropdownVisibleChange={fetchSelectData}
          options={selectOptions}
          allowClear
        />
      </Form.Item>
      <Form.Item label={intl("document")} name="document">
        <Drawer
          url="/documents/list-of-documents"
          name={
            edit
              ? `${sessionData.name_TypeDoc_relation} ${
                  sessionData.num_doc
                } ${dayjs(sessionData.date_doc).format("DD.MM.YYYY")}`
              : undefined
          }
          name2={`${
            rowData["/documents/list-of-documents"]?.data.Name_TypeDoc
          } ${rowData["/documents/list-of-documents"]?.data.num_doc} ${dayjs(
            rowData["/documents/list-of-documents"]?.data.date_doc
          ).format("DD.MM.YYYY")}`}
          setFormFields={setFormFields}
          fieldName={"document"}
        >
          <Documents url="/documents/list-of-documents" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("notes")} name="notes">
        <Input />
      </Form.Item>
    </>
  );

  // items = items.filter(
  //   (item) => !(isOutcoming || isInternal) || item.key !== "3"
  // );

  const onFinish = async (values: any) => {
    console.log("Success:", values);

    if (activeModal === "edit") {
      values.id = sessionData.id;
    }

    const endpoint =
      activeModal === "edit" ? "updatedatatable" : "adddatatable";
    await request(`${props.url || location.pathname}/${endpoint}`, {
      method: "post",
      body: {
        documentId: sessionData?.bta_documents_id,
        page: props.url?.split("/").pop(),
        ...values,
      },
      cancelTokenKey: endpoint,
    });
    activeModal !== "edit" && setDisabled({ ...disabled, edit: false });
    form.resetFields();
    setRefresh(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      className={styles["form"]}
      name="documents"
      // {...layout}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // initialValues={{ head: false, rightControl: false, released: false }}
    >
      {isRelatedDocuments ? (
        relatedDocumentsFormItems
      ) : (
        <Tabs defaultActiveKey="1" items={items} />
      )}
    </Form>
  );
}
