// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_space__jtdjV {
  width: 100%;
}
.styles_space__jtdjV .ant-space-item {
  width: 50%;
}

.styles_title__BNmP9 {
  text-align: center;
}

.styles_header-text__vJsOm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_text__uvUDG {
  display: flex;
  align-items: baseline;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/documents/edit/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACI;EACE,UAAA;AACN;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,qBAAA;AADF","sourcesContent":[".space {\r\n  width: 100%;\r\n  :global {\r\n    .ant-space-item {\r\n      width: 50%;\r\n    }\r\n  }\r\n}\r\n\r\n.title {\r\n  text-align: center;\r\n}\r\n\r\n.header-text {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.text {\r\n  display: flex;\r\n  align-items: baseline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": `styles_space__jtdjV`,
	"title": `styles_title__BNmP9`,
	"header-text": `styles_header-text__vJsOm`,
	"text": `styles_text__uvUDG`
};
export default ___CSS_LOADER_EXPORT___;
