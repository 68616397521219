import styles from "../../../styles.module.scss";

import { createColumn } from '../utils';
import { MergedColumnType } from "../../types";
//UI
import type { ColumnType } from "antd/es/table";
import Checkbox from "antd/lib/checkbox";
//Icons
import PaperClipOutlined from "@ant-design/icons/PaperClipOutlined";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import LinkOutlined from "@ant-design/icons/LinkOutlined";

export const incomingColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  createColumn(<span title={intl("availability-of-attachments")}><PaperClipOutlined /></span>, "fFile", 70, getColumnSearchProps, (a, b) => a.fFile - b.fFile, (text, record) => record.fFile === 1 ? <PaperClipOutlined /> : null),
  // createColumn("K", "fControl", 150, getColumnSearchProps, (a, b) => a.fControl - b.fControl),
  {
    title: (
        <span title={intl("availability-of-control-terms")}>K</span>
    ),
    dataIndex: "fControl",
    key: "fControl",
    align: "center" as const,
    width: 70,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.fControl}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.fControl === value,
  },
  createColumn(<span title={intl("availability-of-discussions")}><MessageOutlined /></span>, "fMessage", 70, getColumnSearchProps, (a, b) => a.fMessage - b.fMessage, (text, record) => record.fMessage === 1 ? <MessageOutlined /> : null),
  createColumn(<span title={intl("availability-of-related-documents")}><LinkOutlined /></span>, "fRelation", 70, getColumnSearchProps, (a, b) => a.fRelation - b.fRelation, (text, record) => record.fRelation === 1 ? <LinkOutlined /> : null),
  createColumn(intl("document-number"), "DocNum", 150, getColumnSearchProps, (a, b) => a.DocNum?.localeCompare(b.DocNum)),
  createColumn(intl("document-date"), "DocDate", 170, getColumnSearchProps, (a, b) => a.DocDate?.localeCompare(b.DocDate)),
  {
    title: intl("project"),
    dataIndex: "fProject",
    key: "fProject",
    align: "center" as const,
    width: 100,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.fProject}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.fProject === value,
  },
  createColumn(intl("type-of-document"), "typeDocName", 350, getColumnSearchProps, (a, b) => a.typeDocName?.localeCompare(b.typeDocName)),
  createColumn(intl("summary"), "Content", 400, getColumnSearchProps, (a, b) => a.Content?.localeCompare(b.Content)),
  createColumn(intl("current-stage"), "ActionCurName", 400, getColumnSearchProps, (a, b) => a.ActionCurName?.localeCompare(b.ActionCurName)),
  createColumn(intl("place-of-registration"), "PlaseRegistration", 350, getColumnSearchProps, (a, b) => a.PlaseRegistration?.localeCompare(b.PlaseRegistration)),
  // createColumn(intl("co-executors"), "CoExecutors", undefined, getColumnSearchProps, (a, b) => a.CoExecutors?.localeCompare(b.CoExecutors)),
  // createColumn(intl("date-of-control"), "DateControl", 170, getColumnSearchProps, (a, b) => a.DateControl?.localeCompare(b.DateControl)),
  // createColumn(intl("recipient"), "Recipient", undefined, getColumnSearchProps, (a, b) => a.Recipient?.localeCompare(b.Recipient)),
  // createColumn(intl("resolution"), "Resolution", undefined, getColumnSearchProps, (a, b) => a.Resolution?.localeCompare(b.Resolution)),
  // {
  //   title: intl("current-stage"),
  //   dataIndex: "actions",
  //   key: "actions",
  // },
];

export const resolutionsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  {
    title: intl("c"),
    dataIndex: "DocControl",
    key: "DocControl",
    align: "center" as const,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.DocControl}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.DocControl === value,
  },
  createColumn(<PaperClipOutlined />, "DocFileCount", undefined, getColumnSearchProps, (a, b) => a.DocFileCount - b.DocFileCount),
  createColumn(intl("registration-number"), "DocNum", undefined, getColumnSearchProps, (a, b) => a.DocNum?.localeCompare(b.DocNum)),
  createColumn(intl("registration-date"), "DocDate", undefined, getColumnSearchProps, (a, b) => a.DocDate?.localeCompare(b.DocDate), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("type-of-document"), "DocType", undefined, getColumnSearchProps, (a, b) => a.DocType?.localeCompare(b.DocType)),
  createColumn(intl("summary"), "DocContent", undefined, getColumnSearchProps, (a, b) => a.DocContent?.localeCompare(b.DocContent)),
  createColumn(intl("correspondent"), "DocCoresp", undefined, getColumnSearchProps, (a, b) => a.DocCoresp?.localeCompare(b.DocCoresp)),
  createColumn(intl("higher-level-resolution"), "ParentActionText", undefined, getColumnSearchProps, (a, b) => a.ParentActionText?.localeCompare(b.ParentActionText)),
  createColumn(intl("author-of-the-resolution"), "ActionPerson", undefined, getColumnSearchProps, (a, b) => a.ActionPerson?.localeCompare(b.ActionPerson)),
  createColumn(intl("resolution-date"), "ActionDate", undefined, getColumnSearchProps, (a, b) => a.ActionDate?.localeCompare(b.ActionDate)),
  createColumn(intl("resolution"), "ActionText", undefined, getColumnSearchProps, (a, b) => a.ActionText?.localeCompare(b.ActionText)),
  {
    // title: intl("current-stage"),
    dataIndex: "actions",
    key: "actions",
    align: "center" as const,
    render: () => <Checkbox />,
  },
];

export const listOfDocumentsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
) => [
  createColumn(intl("type-of-document"), "Name_TypeDoc", undefined, getColumnSearchProps, (a, b) => a.Name_TypeDoc?.localeCompare(b.Name_TypeDoc)),
  createColumn(intl("num-document"), "num_doc", undefined, getColumnSearchProps, (a, b) => a.num_doc?.localeCompare(b.num_doc)),
  createColumn(intl("date"), "date_doc", undefined, getColumnSearchProps, (a, b) => a.date_doc?.localeCompare(b.date_doc), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("summary"), "Content", undefined, getColumnSearchProps, (a, b) => a.Content?.localeCompare(b.Content)),
  createColumn(intl("correspondent"), "Corresp", undefined, getColumnSearchProps, (a, b) => a.Corresp?.localeCompare(b.Corresp)),
  createColumn(intl("num-correspondent"), "EnterNum", undefined, getColumnSearchProps, (a, b) => a.EnterNum?.localeCompare(b.EnterNum)),
  createColumn(intl("correspondent-date"), "EnterDate", undefined, getColumnSearchProps, (a, b) => a.EnterDate?.localeCompare(b.EnterDate), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
];

export const passingColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
) => [
  createColumn(intl("action"), "name_actions_doc", 60, getColumnSearchProps, (a, b) => a.name_actions_doc?.localeCompare(b.name_actions_doc)),
  createColumn(intl("task-content"), "text_prim_full", 100, getColumnSearchProps, (a, b) => a.text_prim_full?.localeCompare(b.text_prim_full)),
  createColumn(intl("executer"), "Executors_FIO_name", 80, getColumnSearchProps, (a, b) => a.Executors_FIO_name?.localeCompare(b.Executors_FIO_name)),
  createColumn(intl("controls"), "Controllers_fio", 80, getColumnSearchProps, (a, b) => a.Controllers_fio?.localeCompare(b.Controllers_fio)),
  createColumn(intl("date-of-receiving"), "dateN_fact", 80, getColumnSearchProps, (a, b) => a.dateN_fact?.localeCompare(b.dateN_fact), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("execution-date"), "dateK_fact", 80, getColumnSearchProps, (a, b) => a.dateK_fact?.localeCompare(b.dateK_fact), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("date-of-control"), "dateK_plan", 80, getColumnSearchProps, (a, b) => a.dateK_plan?.localeCompare(b.dateK_plan), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("result"), "note", 60, getColumnSearchProps, (a, b) => a.Result?.localeCompare(b.Result)),
];

export const relatedDocumentsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
) => [
  createColumn(intl("document-type"), "name_const_typeDoc", 100, getColumnSearchProps, (a, b) => a.Name_TypeDoc?.localeCompare(b.Name_TypeDoc)),
  createColumn(intl("type-of-document"), "Name_TypeDoc", 200, getColumnSearchProps, (a, b) => a.num_doc?.localeCompare(b.num_doc)),
  createColumn(intl("number"), "num_doc", 80, getColumnSearchProps, (a, b) => a.Content?.localeCompare(b.Content)),
  createColumn(intl("date"), "date_doc", 80, getColumnSearchProps, (a, b) => a.date_doc?.localeCompare(b.date_doc), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("type-of-connection"), "name_TypeDoc_relation", 250, getColumnSearchProps, (a, b) => a.Corresp?.localeCompare(b.Corresp)),
  createColumn(intl("notes"), "note", 250, getColumnSearchProps, (a, b) => a.EnterNum?.localeCompare(b.EnterNum)),
];

export const discussionColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
) => [
  createColumn(intl("document-type"), "name_user", 100, getColumnSearchProps, (a, b) => a.name_user?.localeCompare(b.name_user)),
  createColumn(intl("date"), "send_date", 80, getColumnSearchProps, (a, b) => a.send_date?.localeCompare(b.send_date), (date: string) => date && new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("type-of-connection"), "send_text", 250, getColumnSearchProps, (a, b) => a.send_text?.localeCompare(b.send_text)),
];