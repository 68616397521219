import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AdministrationProps } from "./types";
import { SessionContext } from "../../contexts/session/index.context";
import { TranslationContext } from "../../contexts/translation/index.context";
import { MergedColumnType } from "../ui/table/utils/types";
import { request } from "../../services";
import { useTableColumns } from "../ui/table/utils";
//UI
import Space from "antd/lib/space";
import Select from "antd/lib/select";
import Table from "antd/lib/table";
import Spin from "antd/lib/spin";
import Button from "antd/lib/button";
//Icons
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

export default function Administration(props: AdministrationProps) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { tableState, setTableState } = useContext(SessionContext);

  const [selectData, setSelectData] = useState<MergedColumnType[]>([]);
  const [tableData, setTableData] = useState<MergedColumnType[]>([]);
  const [tableDataAccess, setTableDataAccess] = useState<MergedColumnType[]>(
    []
  );

  const [param, setParam] = useState(undefined);
  const [idRowAccess, setIdRowAccess] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingAccess, setLoadingAccess] = useState(false);
  const [reset, setReset] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);

  const selectOptions = selectData.map((item) => ({
    value: item.typeDocID,
    label: item.typeDocName,
  }));

  useEffect(() => {
    fetchDataTable();
  }, []);

  const fetchSelectData = async () => {
    const content = (
      await request(`${location.pathname}/getdoctype`, {
        method: "get",
        cancelTokenKey: "getdoctype",
      })
    )?.content;
    setSelectData(content || []);
  };

  const onSelectChange = (value: number | undefined) => {
    fetchDataTable(value);
  };

  const fetchDataTable = async (id?: number) => {
    setLoading(true);
    const content = (
      await request(`${props.url || location.pathname}/getdatatable`, {
        method: "post",
        body: { id },
        cancelTokenKey: "getdatatable",
      })
    )?.content;
    const dataWithKeys =
      content?.map((item: any) => ({ ...item, key: item.id })) || [];
    setTableData(dataWithKeys || []);
    setLoading(false);
  };

  const fetchDataTableAccess = async (param?: string) => {
    setLoadingAccess(true);
    const content = (
      await request(`${location.pathname}/getdatatableaccess`, {
        method: "post",
        body: { param },
        cancelTokenKey: "getdatatableaccess",
      })
    )?.content;
    setTableDataAccess(content);
    setLoadingAccess(false);
  };

  const onClickRow = async (record: any) => {
    fetchDataTableAccess(record.Param);
    setParam(record.Param);
    setTableState((prevState) => ({ ...prevState, selectedRow: record.id }));
    setAddButtonDisabled(false);
  };

  const handlePageSizeChange = (_current: number, size: number) => {
    setTableState((prevState) => ({ ...prevState, pageSize: size }));
  };

  const handleCurrentPageChange = (page: number) => {
    setTableState((prevState) => ({ ...prevState, currentPage: page }));
  };

  const onClickAdd = async () => {
    await request(`${location.pathname}/adddatatableaccess`, {
      method: "post",
      body: { param },
      cancelTokenKey: "adddatatableaccess",
    });
    fetchDataTableAccess(param);
  };

  const onClickRowAccess = async (record: any) => {
    setIdRowAccess(record.id);
    setDeleteButtonDisabled(false);
  };

  const onClickDelete = async () => {
    await request(`${location.pathname}/deletedatatableaccess`, {
      method: "post",
      body: { id: idRowAccess },
      cancelTokenKey: "deletedatatableaccess",
    });
    fetchDataTableAccess(param);
  };

  const onChange = async (id: number, checked: boolean) => {
    await request(`${location.pathname}/updateaccess`, {
      method: "post",
      body: { id, checked },
      cancelTokenKey: "updateaccess",
    });
    fetchDataTableAccess(param);
  };

  return (
    <Space className={styles["space"]} align="start">
      <Space direction="vertical">
        <Select
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder={intl("document-type")}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={onSelectChange}
          onDropdownVisibleChange={fetchSelectData}
          options={selectOptions}
        />
        <Table
          className={styles["table"]}
          dataSource={tableData}
          columns={useTableColumns(reset, setReset, undefined, tableData)}
          onRow={(record, index) => ({
            onClick: () => {
              onClickRow(record);
            },
          })}
          rowClassName={(record, index) => {
            return record.id === tableState.selectedRow
              ? styles["selected-row"]
              : "";
          }}
          pagination={{
            position: ["topRight"],
            pageSize: tableState.pageSize || 15,
            pageSizeOptions: [15, 20, 50, 100],
            showSizeChanger: true,
            onShowSizeChange: handlePageSizeChange,
            current: tableState.currentPage,
            onChange: (page) => handleCurrentPageChange(page),
          }}
          loading={{
            spinning: loading,
            indicator: (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ),
          }}
          size="small"
        />
      </Space>
      <Space direction="vertical">
        <Space>
          <Button
            className={styles["button"]}
            onClick={onClickAdd}
            icon={<PlusOutlined />}
            disabled={addButtonDisabled}
          />
          <Button
            className={styles["button"]}
            onClick={onClickDelete}
            icon={<DeleteOutlined />}
            disabled={deleteButtonDisabled}
          />
        </Space>
        <Table
          className={styles["table"]}
          dataSource={tableDataAccess}
          columns={useTableColumns(
            reset,
            setReset,
            undefined,
            tableDataAccess,
            true,
            onChange
          )}
          onRow={(record, index) => ({
            onClick: () => {
              onClickRowAccess(record);
            },
          })}
          rowClassName={(record, index) => {
            return record.id === idRowAccess ? styles["selected-row"] : "";
          }}
          pagination={{
            position: ["topRight"],
            pageSize: /* tableState.pageSize || */ 15,
            pageSizeOptions: [15, 20, 50, 100],
            showSizeChanger: true,
            // onShowSizeChange: handlePageSizeChange,
            // current: tableState.currentPage,
            // onChange: (page) => handleCurrentPageChange(page),
          }}
          loading={{
            spinning: loadingAccess,
            indicator: (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ),
          }}
          size="small"
        />
      </Space>
    </Space>
  );
}
