import styles from "./styles.module.scss";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { PopconfirmProps } from "./types";
import { MergedColumnType } from "../table/utils/types";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { ModalContext } from "../../../contexts/modal/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
import { request } from "../../../services";
//UI
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import message from "antd/lib/message";
//Icons
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

export default function PopconfirmComponent(props: PopconfirmProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { disabled, setDisabled, setRefresh } = useContext(ModalContext);
  const { intl } = useContext(TranslationContext);
  const { sessionData } = useContext(SessionContext);
  const location = useLocation();

  const showPopconfirm = () => {
    setOpen(true);
  };

  const info = () => {
    message.error(intl("data-is-not-deleted"));
  };

  const handleOk = async () => {
    setLoading(true);
    const success = (
      await request(`${props.url || location.pathname}/deletedatatable`, {
        method: "post",
        body: {
          id: sessionData?.id,
          documentId: sessionData?.bta_documents_id,
          page: props.url?.split("/").pop(),
        },
        cancelTokenKey: "deletedatatable",
      })
    )?.success;
    if (!success) {
      info();
    } else {
      setRefresh(true);
      sessionStorage.removeItem("row_data");
      const currentTableState = JSON.parse(
        sessionStorage.getItem("table_state") || "null"
      );
      sessionStorage.setItem(
        "table_state",
        JSON.stringify({
          ...currentTableState,
          selectedRow: undefined,
        })
      );
      setDisabled({ ...disabled, edit: true });
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title={intl("question-delete-entry")}
      onConfirm={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading, className: styles["popconfirm"] }}
      okText={intl("ok")}
    >
      <Button
        // className={`${styles["button"]} ${
        //   !disabled.edit && styles["custom-button"]
        // }`}
        className={styles["button"]}
        disabled={disabled.edit}
        icon={<DeleteOutlined />}
      >
        {/* {intl(props.name || "remove")} */}
      </Button>
    </Popconfirm>
  );
}
