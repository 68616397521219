import styles from "./styles.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { DatepickerContext } from "../../../contexts/datepicker/index.context";
import { ModalContext } from "../../../contexts/modal/index.context";
import { TableContext } from "../../../contexts/table/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
import { request } from "../../../services";
import { useTableColumns } from "./utils";
import { MergedColumnType, TableProps } from "./utils/types";
//Components
import FloatButton from "../float-button";
//UI
import Spin from "antd/lib/spin";
import Table from "antd/lib/table";
//Icons
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

export default function TableComponent(props: TableProps) {
  const location = useLocation();
  const [tableData, setTableData] = useState<MergedColumnType[]>([]);
  const [reset, setReset] = useState(false);
  const modalContext = useContext(ModalContext);
  const datePickerContext = useContext(DatepickerContext);
  const {
    buttonStr,
    checkboxStr,
    rowData,
    setRowData,
    numenclatureId,
    setContextTableData,
  } = useContext(TableContext);
  const { tableState, setTableState, sessionData, setSessionData } =
    useContext(SessionContext);
  const isEmployeesAndContacts =
    location.pathname === "/directories/employees-and-contact-persons";
  const isEmployeesAndContactsProp =
    props.url === "/directories/employees-and-contact-persons";
  const isNomenclatureOfCases =
    (props.url || location.pathname) === "/directories/nomenclature-of-cases";
  const isResolutionTemplates =
    location.pathname === "/directories/resolution-templates";
  const isExecutors =
    props.url === "/directories/resolution-templates/executors";
  const isTypesOfDocuments =
    (props.url || location.pathname) === "/directories/types-of-documents";
  const isStructuralUnits =
    (props.url || location.pathname) === "/directories/structural-units";
  const isRelatedDocuments = props.url === "/documents/related-documents";
  const isScroll =
    location.pathname.includes("documents") ||
    location.pathname === "/directories/nomenclature-of-cases-current" ||
    location.pathname === "/directories/employees" ||
    location.pathname === "/directories/structural-units";
  const tableRef = useRef(null);

  useEffect(() => {
    const previousRoute = sessionStorage.getItem("current_route");
    const currentRoute = location.pathname;
    sessionStorage.setItem("current_route", currentRoute);
    if (previousRoute && previousRoute !== currentRoute) {
      Object.keys(sessionStorage).forEach((key) => {
        if (key.startsWith("session_data_") || key.startsWith("table_state_")) {
          sessionStorage.removeItem(key);
        }
      });
      setTableState({
        selectedRow: undefined,
        pageSize: 15,
        currentPage: 1,
      });
      setSessionData({});
    }
  }, [location]);

  // useEffect(() => {
  //   sessionStorage.setItem("table_state", JSON.stringify(tableState));
  // }, [tableState]);

  // useEffect(() => {
  //   if (props.tableData) {
  //     setTableData((prevTableData) => {
  //       const newData = [...prevTableData];
  //       props.tableData?.forEach((newRow) => {
  //         if (!newData.some((existingRow) => existingRow.id === newRow.id)) {
  //           newData.push(newRow);
  //         }
  //       });

  //       return newData;
  //     });
  //   }
  // }, [props.tableData]);

  useEffect(() => {
    if (modalContext.refresh) {
      fetchDataTable(numenclatureId, `${buttonStr} ${checkboxStr.trim()}`);
      modalContext.setRefresh(false);
    }
  }, [modalContext.refresh]);

  useEffect(() => {
    if (buttonStr || checkboxStr) {
      fetchDataTable(undefined, `${buttonStr} ${checkboxStr.trim()}`);
    }
  }, [buttonStr, checkboxStr]);

  useEffect(() => {
    Object.keys(sessionData).length > 0 &&
      modalContext.setDisabled({ ...modalContext.disabled, edit: false });
    props.directories &&
      fetchDataTable(
        /* isExecutors && props.activeModal === "edit" ?  */ props.id /* : undefined */,
        `${buttonStr} ${checkboxStr.trim()}`
      );
  }, []);

  const fetchDataTable = async (id?: number, str?: string) => {
    datePickerContext.setLoading(true);

    const content = (
      await request(`${props.url || location.pathname}/getdatatable`, {
        method:
          ((isEmployeesAndContacts || isResolutionTemplates) && !props.url) ||
          isExecutors ||
          isNomenclatureOfCases ||
          isEmployeesAndContactsProp ||
          isTypesOfDocuments ||
          isStructuralUnits ||
          isRelatedDocuments
            ? "post"
            : "get",
        body:
          isEmployeesAndContacts ||
          isNomenclatureOfCases ||
          isExecutors ||
          isTypesOfDocuments ||
          isStructuralUnits ||
          isRelatedDocuments
            ? { id, str, type: props.type }
            : undefined,
        cancelTokenKey: "getdatatable",
      })
    )?.content;
    const dataWithKeys =
      content?.map((item: any) => ({ ...item, key: item.id })) || [];
    setTableData(dataWithKeys || []);
    datePickerContext.setLoading(false);
  };

  const onClickRow = async (record: any) => {
    let content;
    if (isEmployeesAndContacts) {
      content = (
        await request(`${location.pathname}/getdatatable`, {
          method: "post",
          body: { id: record.id },
          cancelTokenKey: "getdatatable",
        })
      )?.content;
    }
    modalContext.setDisabled({ ...modalContext.disabled, edit: false });
    setTableState((prevState) => ({ ...prevState, selectedRow: record.id }));
    if (props.url) {
      const key = props.name
        ? `${props.url || location.pathname}/${props.name}`
        : props.url || location.pathname;
      const newTableData = {
        ...rowData,
        [key]: { id: record.id, data: record },
      };
      setRowData(newTableData);
    }
    setSessionData(content?.[0] ?? record);
  };

  const handlePageSizeChange = (_current: number, size: number) => {
    setTableState((prevState) => ({ ...prevState, pageSize: size }));
  };

  const handleCurrentPageChange = (page: number) => {
    setTableState((prevState) => ({ ...prevState, currentPage: page }));
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys: any, selectedRows: any) => {
  //     const modifiedRows = selectedRows.map((row: any, index: number) => ({
  //       ExecutorFIO: row.contact_person,
  //       num_pp: index + 1,
  //     }));
  //     setContextTableData(modifiedRows);
  //     const selectedIds = selectedRows.map((row: any) => row.id);
  //     props.form?.setFieldsValue({ executors: selectedIds });
  //   },
  // };

  return (
    <div ref={tableRef} className={styles["table-container"]}>
      <Table
        className={styles["table"]}
        key={reset ? "reset" : "not-reset"}
        columns={useTableColumns(reset, setReset, props.url, tableData)}
        dataSource={
          /* props.tableData && props.tableData.length > 0
            ? props.tableData
            :  */ tableData.length > 0 ? tableData : datePickerContext.tableData
        }
        onRow={(record, index) => ({
          onClick: () => {
            onClickRow(record);
          },
        })}
        rowClassName={(record, index) => {
          return record.id === tableState.selectedRow
            ? styles["selected-row"]
            : "";
        }}
        // rowSelection={isEmployeesAndContactsProp ? rowSelection : undefined}
        pagination={{
          position: ["topRight"],
          pageSize: tableState.pageSize || 15,
          pageSizeOptions: [15, 20, 50, 100],
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
          current: tableState.currentPage,
          onChange: (page) => handleCurrentPageChange(page),
        }}
        // scroll={{
        //   x: isScroll ? 2500 : undefined,
        //   // y: "calc(100vh - 300px)",
        // }}
        scroll={isScroll ? { x: "100vw" } : undefined}
        sticky
        loading={{
          spinning: datePickerContext.loading,
          indicator: (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ),
        }}
        size="small"
        // bordered
      />
      <FloatButton tableRef={tableRef} />
    </div>
  );
}
