import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { request } from "../../../services";
import { DatePickersProps } from "./types";
import { MergedColumnType } from "../table/utils/types";

import { DatepickerContext } from "../../../contexts/datepicker/index.context";
import { ModalContext } from "../../../contexts/modal/index.context";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
//UI
import Space from "antd/lib/space";
import DatePicker from "antd/lib/date-picker";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import type { DatePickerProps } from "antd/es/date-picker";
import Checkbox from "antd/lib/checkbox";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
//Locale
import "dayjs/locale/uk";

dayjs.locale("uk");

const { Search } = Input;

export default function DatePickersComponent(props: DatePickersProps) {
  const location = useLocation();
  const isListOfDocuments = (props.url || location.pathname) === "/documents/list-of-documents";
  const context = useContext(ModalContext);
  const datePickerContext = useContext(DatepickerContext);
  const { intl } = useContext(TranslationContext);
  const { setSessionData } = useContext(SessionContext);
  const dateFormatList = ["DD.MM.YYYY", "DD-MM-YYYY"];
  const startOf = isListOfDocuments ? "month" : "year";
  const [typeDocId, setTypeDocId] = useState<number | undefined>(undefined);
  const [selectData, setSelectData] = useState<MergedColumnType[]>([]);
  const [from, setFrom] = useState<Dayjs>(dayjs().startOf(startOf));
  const [to, setTo] = useState<Dayjs>(dayjs().endOf(startOf));
  const [inWork, setInWork] = useState(false);
  const [str, setStr] = useState<string | undefined>(undefined);

  const selectOptions = selectData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // useEffect(() => {
  //   fetchData(from, to);
  // }, []);

  useEffect(() => {
    if (onChangeFrom || context.refresh) {
      fetchData(from, to);
      context.setRefresh(false);
    }
  }, [context.refresh]);

  // const updateObject = (arr: any, obj: any) => {
  //   const foundObj = arr.find(item => item.id === obj.id);
  //   if (foundObj) {
  //     Object.assign(obj, foundObj);
  //   }
  // };

  const fetchSelectData = async () => {
    const content = (
      await request(`${location.pathname}/getdoctype`, {
        method: "get",
        cancelTokenKey: "getdoctype",
      })
    )?.content;
    setSelectData(content || []);
  };

  const onSelectChange = (value: number | undefined) => {
    setTypeDocId(value);
    fetchData(from, to, undefined, value, str);
  };

  const fetchData = async (
    from: Dayjs,
    to: Dayjs,
    inWork?: boolean,
    typeDocId?: number,
    str?: string
  ) => {
    datePickerContext.setLoading(true);
    const content = (
      await request(`${props.url || location.pathname}/getdatatable`, {
        method: "post",
        body: {
          typeDocId,
          from,
          to,
          inWork,
          str,
        },
        cancelTokenKey: "getdatatable",
      })
    )?.content;
    datePickerContext.setTableData(content);
    datePickerContext.setLoading(false);
  };

  const onChangeFrom: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      setFrom(date);
      fetchData(date, to);
    }
  };

  const onChangeTo: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      setTo(date);
      fetchData(from, date);
    }
  };

  const onChangeInWork = async (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setInWork(e.target.checked);
      fetchData(from, to, e.target.checked);
    }
  };

  const onSearch = (value: string) => {
    setStr(value);
    fetchData(from, to, undefined, typeDocId, value);
  };

  return (
    <Space size={"middle"} className={styles["datepicker-space"]}>
      {isListOfDocuments && (
        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder={intl("document-type")}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={onSelectChange}
          onDropdownVisibleChange={fetchSelectData}
          options={selectOptions}
        />
      )}
      <DatePicker
        defaultValue={dayjs().startOf(startOf)}
        format={dateFormatList}
        onChange={onChangeFrom}
      />
      <DatePicker
        defaultValue={dayjs().endOf(startOf)}
        format={dateFormatList}
        onChange={onChangeTo}
      />
      {isListOfDocuments ? (
        <Tooltip title={intl("the-search-is-performed")}>
          <Search
            placeholder={intl("search")}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Tooltip>
      ) : (
        <Checkbox onChange={onChangeInWork}>{intl("in-work")}</Checkbox>
      )}
    </Space>
  );
}
