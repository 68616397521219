import styles from "../../../../styles.module.scss";
import dayjs from "dayjs";

import { MergedColumnType } from "../../../types";
import { createColumn } from "../../utils";
//UI
import type { ColumnType, ColumnsType } from "antd/es/table";
import Checkbox from "antd/lib/checkbox";

export const nomenclatureOfCasesCurrentColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  {
    title: intl("valid"),
    dataIndex: "isActive",
    key: "isActive",
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.isActive}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.isActive === value,
  },
  createColumn(
    intl("structural-unit"),
    "NAME_OTDEL",
    undefined,
    getColumnSearchProps,
    (a, b) => a.NAME_OTDEL?.localeCompare(b.NAME_OTDEL)
  ),
  createColumn("№", "ReestrNum", undefined, getColumnSearchProps, (a, b) =>
    a.ReestrNum?.localeCompare(b.ReestrNum)
  ),
  createColumn(
    intl("year"),
    "ReestrDate",
    undefined,
    getColumnSearchProps,
    (a, b) => a.ReestrDate - b.ReestrDate
  ),
  createColumn(
    intl("date-of-approval"),
    "ApprovalDate",
    undefined,
    getColumnSearchProps,
    (a, b) => {
      const dateA = a.ApprovalDate
        ? dayjs(a.ApprovalDate, "DD.MM.YYYY")
        : dayjs(0);
      const dateB = b.ApprovalDate
        ? dayjs(b.ApprovalDate, "DD.MM.YYYY")
        : dayjs(0);
      return dateA.diff(dateB);
    }
  ),
  createColumn(
    intl("document"),
    "ApprovalDoc",
    undefined,
    getColumnSearchProps,
    (a, b) => a.ApprovalDoc?.localeCompare(b.ApprovalDoc)
  ),
  createColumn(
    intl("notes"),
    "ApprovalDescription",
    undefined,
    getColumnSearchProps,
    (a, b) => a.ApprovalDescription?.localeCompare(b.ApprovalDescription)
  ),
  {
    title: intl("add-num-nomenclature"),
    dataIndex: "isAddNum",
    key: "isAddNum",
    align: "center" as const,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.isAddNum}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.isAddNum === value,
  },
  {
    title: intl("add-letter-c"),
    dataIndex: "isAddC",
    key: "isAddC",
    align: "center" as const,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.isAddC}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.isAddC === value,
  },
  createColumn(
    intl("general-notes"),
    "Description",
    undefined,
    getColumnSearchProps,
    (a, b) => a.Description?.localeCompare(b.Description)
  ),
];

export const nomenclatureOfCasesColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>
) => [
  createColumn(
    intl("index"),
    "Code_OfficialDoc",
    200,
    getColumnSearchProps,
    (a, b) => a.Code_OfficialDoc?.localeCompare(b.Code_OfficialDoc)
  ),
  createColumn(
    intl("heading"),
    "Name_OfficialDoc",
    undefined,
    getColumnSearchProps,
    (a, b) => a.Name_OfficialDoc?.localeCompare(b.Name_OfficialDoc)
  ),
  createColumn(
    intl("shelf-life"),
    "LimitationText",
    200,
    getColumnSearchProps,
    (a, b) => a.LimitationText?.localeCompare(b.LimitationText)
  ),
  createColumn(
    intl("notes"),
    "Prim_OfficialDoc",
    undefined,
    getColumnSearchProps,
    (a, b) => a.Prim_OfficialDoc?.localeCompare(b.Prim_OfficialDoc)
  ),
];

export const resolutionTemplatesColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>
) => [
  createColumn(
    intl("text-resolution"),
    "text_rezoluzia",
    undefined,
    getColumnSearchProps,
    (a, b) => a.text_rezoluzia?.localeCompare(b.text_rezoluzia)
  ),
  createColumn(
    intl("performers"),
    "str_FioEcecuters",
    undefined,
    getColumnSearchProps,
    (a, b) => a.str_FioEcecuters?.localeCompare(b.str_FioEcecuters)
  ),
  createColumn(intl("author"), "fio", undefined, getColumnSearchProps, (a, b) =>
    a.fio?.localeCompare(b.fio)
  ),
];

export const executorsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>
) => [
  createColumn(
    intl("by-order"),
    "num_pp",
    undefined,
    getColumnSearchProps,
    (a, b) => a.num_pp - b.num_pp
  ),
  createColumn(
    intl("performer"),
    "ExecutorFIO",
    undefined,
    getColumnSearchProps,
    (a, b) => a.ExecutorFIO?.localeCompare(b.ExecutorFIO)
  ),
];

export const documentCharColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[],
  data?: MergedColumnType[],
  visible?: boolean
) => {
  return [
  createColumn(intl("code"), "Code", undefined, getColumnSearchProps, (a, b) =>
    a.Code?.localeCompare(b.Code)
  ),
  createColumn(intl("name"), "Name", undefined, getColumnSearchProps, (a, b) =>
    a.Name?.localeCompare(b.Name)
  ),
  createColumn(
    intl("notes"),
    "Notes",
    undefined,
    getColumnSearchProps,
    (a, b) => a.Notes?.localeCompare(b.Notes)
  ),
  visible && {
    title: intl("deleted"),
    dataIndex: "del",
    key: "del",
    align: "center" as const,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.del}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.del === value,
  },
].filter(Boolean) as ColumnsType<MergedColumnType>;}