import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";

import { RadioGroupProps } from "./types";
import { TableContext } from "../../../contexts/table/index.context";
import { TranslationContext } from "../../../contexts/translation/index.context";
//UI
import Radio from "antd/lib/radio";
import type { RadioChangeEvent } from "antd/es/radio";

export default function RadioGroupComponent(props: RadioGroupProps) {
  const [value, setValue] = useState(1);
  const { setCheckboxStr } = useContext(TableContext);
  const { intl } = useContext(TranslationContext);

  const filterStrings = {
    1: "and (sta_otdel.id=sta_otdel.reorganize_otdel_id or isnull(sta_otdel.reorganize_otdel_id,0)=0) and isnull(sta_otdel.is_liquidated,0)=0",
    2: "and (sta_otdel.id<>sta_otdel.reorganize_otdel_id and sta_otdel.reorganize_otdel_id>0)",
    3: "and sta_otdel.is_liquidated=1",
    4: "",
  };

  // useEffect(() => {
  //   setCheckboxStr(filterStrings[1]);
  // }, []);

  const onChange = (e: RadioChangeEvent) => {
    const value = e.target.value as keyof typeof filterStrings;
    setValue(value);
    setCheckboxStr(filterStrings[value].trim());
  };

  return (
    <Radio.Group
      className={styles["radio-group"]}
      onChange={onChange}
      value={value}
    >
      <Radio value={1}>{intl("current")}</Radio>
      <Radio value={2}>{intl("reorganized")}</Radio>
      <Radio value={3}>{intl("liquidated2")}</Radio>
      <Radio value={4}>{intl("all")}</Radio>
    </Radio.Group>
  );
}
