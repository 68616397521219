import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FormInstance } from "antd";

import { MergedColumnType } from "../../../../../../ui/table/utils/types";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

export const setHrDepartment: Record<
  string,
  (form: FormInstance, rowData: MergedColumnType) => void
> = {
  "/directories/profession-classifier": (form, rowData) => {
    form.setFieldsValue({
      name: rowData.Name,
      kp: rowData.KP,
      okp: rowData.OKP,
      etks: rowData.ETKS,
    });
  },
  "/directories/job-categories": (form, rowData) => {
    form.setFieldsValue({
      name: rowData.Name,
      code: rowData.Code,
    });
  },
  "/directories/types-of-structural-unit": (form, rowData) => {
    form.setFieldsValue({
      name: rowData.Name,
      code: rowData.Code,
      notes: rowData.Notes,
    });
  },
  "/directories/employees": (form, rowData) => {
    form.setFieldsValue({
      structuralUnit: rowData.otdel_id,
      position: rowData.posada_id,
      lastName: rowData.SURNAME,
      firstName: rowData.NAME,
      middleName: rowData.OTCHESTVO,
      yearBirth: rowData.GOD_ROGDEN,
      monthBirth: rowData.MES_ROGDEN,
      dayBirth: rowData.CHISLO_ROGDEN,
      forEnvelopes: rowData.fio_dp,
      telephone: rowData.WORK_TEL,
      fax: rowData.WORK_FAX,
      mobilePhone: rowData.MOBILE_TEL,
      email: rowData.WORK_E_MAIL,
      noCabinet: rowData.N_ROOM,
      head: rowData.IS_KERIVNIK,
      rightControl: rowData.IS_CONTROL,
      released: rowData.is_uvolen,
    });
  },
  "/directories/structural-units": (form, rowData) => {
    form.setFieldsValue({
      code: rowData.Code,
      subdivision: rowData.sta_type_subdivision_id,
      department: rowData.Name,
      shortName: rowData.name_otdel_short,
      refersTo: rowData.parent_id,
      reorganized: rowData.reorganize_otdel_id,
      notes: rowData.prim,
      isLiquidated: rowData.is_liquidated,
    });
  },
  "/directories/positions": (form, rowData) => {
    form.setFieldsValue({
      category: rowData.KATEG_ID,
      code: rowData.Code,
      nameProf: rowData.PROF_id,
      position: rowData.Name,
      description: rowData.OPISAN,
    });
  },
  "/directories/employees-and-contact-persons": (form, rowData) => {
    form.setFieldsValue({
      enterpriseOrganization: rowData.sta_kontragent_id,
      structuralUnit: rowData.otdel_id,
      structuralUnit2: rowData.name_otdel,
      position: rowData.posada_id,
      lastName: rowData.SURNAME,
      firstName: rowData.NAME,
      middleName: rowData.OTCHESTVO,
      birthday:
        rowData.GOD_ROGDEN &&
        dayjs(
          `${rowData.GOD_ROGDEN}-${rowData.MES_ROGDEN}-${rowData.CHISLO_ROGDEN}`,
          "YYYY-M-D"
        ),
      yearBirth: rowData.GOD_ROGDEN,
      monthBirth: rowData.MES_ROGDEN,
      dayBirth: rowData.CHISLO_ROGDEN,
      forEnvelopes: rowData.fio_dp,
      telephone: rowData.WORK_TEL,
      fax: rowData.WORK_FAX,
      mobilePhone: rowData.MOBILE_TEL,
      email: rowData.WORK_E_MAIL,
      noCabinet: rowData.N_ROOM,
      head: rowData.IS_KERIVNIK,
      rightControl: rowData.IS_CONTROL,
      released: rowData.is_uvolen,
    });
  },
};
