import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { TranslationContext } from "../../../../../../../contexts/translation/index.context";
import { FormContext } from "../../../../../../../contexts/form/index.context";
import { ModalContext } from "../../../../../../../contexts/modal/index.context";
import { TableContext } from "../../../../../../../contexts/table/index.context";
import { SessionContext } from "../../../../../../../contexts/session/index.context";
import { MergedColumnType } from "../../../../../../ui/table/utils/types";
import { request } from "../../../../../../../services";
//Components
import Drawer from "../../../../../../ui/drawer";
import Directories from "../../../../../../directories";
import Documents from "../../../../../../documents";
//UI
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Space from "antd/lib/space";
import DatePicker from "antd/lib/date-picker";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

const { TextArea } = Input;

export function Passing({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { sessionData } = useContext(SessionContext);
  const { rowData } = useContext(TableContext);
  const edit = activeModal === "edit";
  const [selectData, setSelectData] = useState<MergedColumnType[]>([]);
  const [selectDataControlType, setSelectDataControlType] = useState<
    MergedColumnType[]
  >([]);
  const [selectedActionStage, setSelectedActionStage] = useState<number | null>(
    null
  );
  const dateFormatList = ["DD.MM.YYYY", "DD-MM-YYYY"];

  const selectOptions = selectData.map((item) => ({
    value: item.id,
    label: item.name,
    isKontrol: item.isKontrol,
  }));

  const selectOptionsControlType = selectDataControlType.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (sessionData?.isKontrol === 1) {
      setSelectedActionStage(1);
    }
  }, [sessionData]);

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/employees-and-contact-persons"]) {
        fieldsValue.performer =
          rowData["/directories/employees-and-contact-persons"].data.exec_id;
        fieldsValue.performerType =
          rowData["/directories/employees-and-contact-persons"].data.typerec;
        fieldsValue.performerFioId =
          rowData[
            "/directories/employees-and-contact-persons"
          ].data.exec_fio_id;
      }
      if (rowData["/directories/employees"]) {
        fieldsValue.controls = rowData["/directories/employees"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  const fetchSelectData = async () => {
    const content = (
      await request(`${pathname}/getdataselect`, {
        method: "post",
        cancelTokenKey: "getdataselect",
      })
    )?.content;
    setSelectData(content || []);
  };

  const fetchSelectDataControlType = async () => {
    const content = (
      await request(`${pathname}/getdataselect`, {
        method: "post",
        body: { str: "controlType" },
        cancelTokenKey: "getdataselect",
      })
    )?.content;
    setSelectDataControlType(content || []);
  };

  return (
    <>
      <Form.Item
        label={intl("action-stage")}
        name="actionStage"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          labelInValue
          onChange={(value) => {
            const selectedOption = selectData.find(
              (item) => item.id === value?.value
            );
            setSelectedActionStage(
              selectedOption?.isKontrol === 1 ? value.value : null
            );
            form.setFieldsValue({ actionStage: value });
          }}
          onDropdownVisibleChange={fetchSelectData}
          options={selectOptions}
          allowClear
          disabled={edit}
        />
      </Form.Item>
      <Form.Item
        label={intl("performer")}
        name="performer"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Drawer
          url="/directories/employees-and-contact-persons"
          name={edit ? sessionData.Executors_FIO_name : undefined}
          name2={
            rowData["/directories/employees-and-contact-persons"]?.data
              .contact_person
          }
          setFormFields={setFormFields}
          fieldName={"performer"}
        >
          <Directories url="/directories/employees-and-contact-persons" />
        </Drawer>
      </Form.Item>
      <Form.Item name="performerType" hidden />
      <Form.Item name="performerFioId" hidden />
      <Form.Item label={intl("controls")} name="controls">
        <Drawer
          url="/directories/employees"
          name={edit ? sessionData.Controllers_fio : undefined}
          name2={rowData["/directories/employees"]?.data.fio}
          setFormFields={setFormFields}
          fieldName={"controls"}
        >
          <Directories url="/directories/employees" />
        </Drawer>
      </Form.Item>
      <Space>
        <Form.Item
          label={intl("date-of-transfer-to-the-contractor")}
          name="dateOfTransferToTheContractor"
        >
          <DatePicker format={dateFormatList} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label={intl("date-of-control")} name="dateOfControl">
          <DatePicker
            format={dateFormatList}
            style={{ width: "100%" }}
            disabled={selectedActionStage ? false : true}
          />
        </Form.Item>
        <Form.Item
          label={intl("scheduled-execution-date")}
          name="scheduledExecutionDate"
        >
          <DatePicker
            format={dateFormatList}
            style={{ width: "100%" }}
            disabled={selectedActionStage ? true : false}
          />
        </Form.Item>
      </Space>
      {selectedActionStage && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.Item label={intl("control-type")} name="controlType">
            <Select
              labelInValue
              onChange={(value) => {
                form.setFieldsValue({ controlType: value });
              }}
              onDropdownVisibleChange={fetchSelectDataControlType}
              options={selectOptionsControlType}
              allowClear
              disabled={edit}
            />
          </Form.Item>
          <Space>
            <Form.Item label={intl("paragraph-no")} name="paragraphNo">
              <Input />
            </Form.Item>
            <Form.Item label={intl("appendix-no")} name="appendixNo">
              <Input />
            </Form.Item>
          </Space>
          <Form.Item label={intl("notes")} name="controlNotes">
            <Input />
          </Form.Item>
        </Space>
      )}
      <Form.Item label={intl("content-action-stage")} name="contentActionStage">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label={intl("notes")} name="notes">
        <Input />
      </Form.Item>
    </>
  );
}

export function RelatedDocuments({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { sessionData } = useContext(SessionContext);
  const { rowData } = useContext(TableContext);
  const edit = activeModal === "edit";
  const [selectData, setSelectData] = useState<MergedColumnType[]>([]);

  const selectOptions = selectData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/types-of-communication"]) {
        fieldsValue.typesOfCommunication =
          rowData["/directories/types-of-communication"].id;
      }
      if (rowData["/documents/list-of-documents"]) {
        fieldsValue.document = rowData["/documents/list-of-documents"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  const fetchSelectData = async () => {
    const content = (
      await request(`${pathname}/getdataselect`, {
        method: "get",
        cancelTokenKey: "getdataselect",
      })
    )?.content;
    setSelectData(content || []);
  };

  return (
    <>
      <Form.Item
        label={intl("types-of-communication")}
        name="typesOfCommunication"
        rules={[
          {
            required: true,
            // message: intl("last-name-required"),
          },
        ]}
      >
        <Drawer
          url="/directories/types-of-communication"
          name={edit ? sessionData.name_TypeDoc_relation : undefined}
          name2={rowData["/directories/types-of-communication"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"typesOfCommunication"}
        >
          <Directories url="/directories/types-of-communication" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("document-type")} name="documentType">
        <Select
          labelInValue
          onChange={(value) => {
            form.setFieldsValue({ documentType: value });
          }}
          onDropdownVisibleChange={fetchSelectData}
          options={selectOptions}
          allowClear
          disabled={edit}
        />
      </Form.Item>
      <Form.Item
        label={intl("document")}
        name="document"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Drawer
          url="/documents/list-of-documents"
          name={
            edit
              ? `${sessionData.Name_TypeDoc ?? ""} ${
                  sessionData.num_doc ?? ""
                } ${
                  sessionData.date_doc
                    ? dayjs(sessionData.date_doc).format("DD.MM.YYYY")
                    : ""
                }`
              : undefined
          }
          name2={`${
            rowData["/documents/list-of-documents"]?.data.Name_TypeDoc ?? ""
          } ${
            rowData["/documents/list-of-documents"]?.data.num_doc ?? ""
          } ${intl("from")} ${dayjs(
            rowData["/documents/list-of-documents"]?.data.date_doc
          ).format("DD.MM.YYYY")}`}
          setFormFields={setFormFields}
          fieldName={"document"}
          disabled={edit}
        >
          <Documents url="/documents/list-of-documents" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("notes")} name="notes">
        <Input />
      </Form.Item>
    </>
  );
}

export function Discussion({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);

  return (
    <>
      <Form.Item label={intl("message")} name="message">
        <Input />
      </Form.Item>
    </>
  );
}
