import styles from "./styles.module.scss";

import { DirectoriesProps } from "./types";
import { ModalProvider } from "../../contexts/modal/index.context";
import { SessionProvider } from "../../contexts/session/index.context";
import {
  TableProvider,
  TableContext,
} from "../../contexts/table/index.context";
//Components
import DocumentsButtons from "../ui/documents-buttons";
import Table from "../ui/table";

export default function Directories(props: DirectoriesProps) {
  return (
    <ModalProvider>
      {/* <TableProvider> */}
      <SessionProvider url={props.url}>
        <DocumentsButtons url={props.url} form={props.form} id={props.id} />
        <Table
          directories={true}
          url={props.url}
          name={props.name}
          id={props.id}
          activeModal={props.activeModal}
          form={props.form}
          tableData={props.tableData}
          type={props.type}
        />
      </SessionProvider>
      {/* </TableProvider> */}
    </ModalProvider>
  );
}
