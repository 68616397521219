import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";

import { DrawerProps, DataType } from "./types";
import { DrawerContext } from "../../../contexts/drawer/index.context";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { FormContext } from "../../../contexts/form/index.context";
//UI
import Space from "antd/lib/space";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Drawer from "antd/lib/drawer";
import Table from "antd/lib/table";
import Spin from "antd/lib/spin";
//Icons
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

export default function DrawerComponent(props: DrawerProps) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<DataType | any>(
    props.name /* undefined */
  );
  // const [data, setData] = useState(undefined);
  // const [loading, setLoading] = useState(false);
  // const [disabled, setDisabled] = useState(true);
  // const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const drawerContext = useContext(DrawerContext);
  const { form } = useContext(FormContext);
  const { intl } = useContext(TranslationContext);

  // useEffect(() => {
  //   if (props.url && rowData[props.url]) {
  //     setContent(rowData[props.url].name);
  //   }
  // }, [props.url, rowData]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOk = () => {
    setOpen(false);
    props.setFormFields?.();
    setContent(props.name2);
  };

  const onClearContent = () => {
    setContent(undefined);
    props.fieldName && form.setFieldsValue({ [props.fieldName]: undefined });
  };

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setContent(event.target.value);
  //   props.onRowClick?.(event.target.value);
  // };

  // const onClickRow = (record: any) => {
  //   setContent(record.Name);
  //   setDisabled(false);
  //   props.onRowClick(record.id);
  // };

  return (
    <Space className={styles["space"]} size={4}>
      <Input
        className={styles["input"]}
        value={content}
        // onChange={handleInputChange}
        disabled={props.inputDisabled ?? true}
      />
      <Button
        icon={<EllipsisOutlined />}
        onClick={showDrawer /* () => drawerContext.showDrawer() */}
        disabled={props.disabled}
      />
      <Button
        icon={<CloseOutlined />}
        onClick={onClearContent}
        disabled={props.disabled}
      />
      <Drawer
        className={styles["drawer"]}
        placement="top"
        onClose={onClose /* () => drawerContext.onClose() */}
        open={/* drawerContext.open || */ open}
        closable={false}
        footer={
          <Space>
            <Button
              onClick={
                onClose /* () => {
                  drawerContext.onClose();
                  // props.onRowClick(null);
                } */
              }
            >
              {intl("cancel")}
            </Button>
            <Button
              className={styles["button"]}
              type="primary"
              onClick={
                onOk /* () => {
                  // setContent(props.inputValue);
                  drawerContext.onClose();
                } */
              }
              // disabled={/* disabled */ props.disabled}
            >
              {intl("ok")}
            </Button>
          </Space>
        }
      >
        {props.children}
      </Drawer>
    </Space>
  );
}
