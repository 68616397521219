import styles from "./styles.module.scss";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { DocumentsButtonsProps } from "./types";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { FormProvider } from "../../../contexts/form/index.context";
import { ModalContext } from "../../../contexts/modal/index.context";
//Components
import Modal from "../modal";
import DropdownButtons from "../dropdown-buttons";
import DocumentsForm from "../../forms/documents/add";
import DocumentsEditForm from "../../forms/documents/edit";
import DirectoriesForm from "../../forms/directories";
import Popconfirm from "../popconfirm";
import Select from "../select";
import CheckboxGroup from "../checkbox-group";
import RadioGroup from "../radio-group";
import Directories from "../../directories";
//UI
import Space from "antd/lib/space";
import FormRef from "antd/lib/form";
import Button from "antd/lib/button";
import Dropdown from "antd/lib/dropdown";
import Tooltip from "antd/lib/tooltip";
import type { MenuProps } from "antd/es/menu/menu";
//Icons
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import VerticalAlignTopOutlined from "@ant-design/icons/VerticalAlignTopOutlined";
import ArrowUpOutlined from "@ant-design/icons/ArrowUpOutlined";
import ArrowDownOutlined from "@ant-design/icons/ArrowDownOutlined";
import VerticalAlignBottomOutlined from "@ant-design/icons/VerticalAlignBottomOutlined";

export default function DocumentsButtonsComponent(
  props: DocumentsButtonsProps
) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { disabled } = useContext(ModalContext);
  const isDocuments = location.pathname.includes("documents");
  const isEditFormComponent =
    location.pathname.includes("documents") &&
    props.url !== "/documents/related-documents";
  const formComponent = isDocuments ? (
    <DocumentsForm url={props.url} />
  ) : (
    <DirectoriesForm url={props.url} />
  );
  const editFormComponent = <DocumentsEditForm />;
  const pathname = props.url || location.pathname;
  const isNomenclatureOfCases =
    pathname === "/directories/nomenclature-of-cases";
  const isCheckboxGroup =
    pathname === "/directories/employees-and-contact-persons" ||
    pathname === "/directories/types-of-documents";
  const isRadioGroup = pathname === "/directories/structural-units";
  const modalNames = ["contact-person", "employee", "add", "edit"];
  const positionButtons: {
    [key: string]: { icon: JSX.Element; tooltip: string };
  } = {
    "to-first": { icon: <VerticalAlignTopOutlined />, tooltip: "to-first" },
    "to-previous": { icon: <ArrowUpOutlined />, tooltip: "to-previous" },
    "to-next": { icon: <ArrowDownOutlined />, tooltip: "to-next" },
    "to-last": { icon: <VerticalAlignBottomOutlined />, tooltip: "to-last" },
  };

  const dropdown = (
    <Dropdown
      className={styles["dropdown"]}
      menu={{
        items: modalNames.slice(0, 2).map((name) => ({
          key: name,
          label: <Modal name={name}>{formComponent}</Modal>,
        })),
      }}
      trigger={["click"]}
      placement="bottomLeft"
    >
      <Button className={styles["button"]} icon={<PlusOutlined />} />
    </Dropdown>
  );

  // const updatePosition = async (type: string) => {
  //   if (
  //     (type === "to-first" || type === "to-previous") &&
  //     rowData.executors?.num_pp === 1
  //   )
  //     return;
  //   if (
  //     (type === "to-last" || type === "to-next") &&
  //     rowData.executors?.num_pp === data.executors.length
  //   )
  //     return;
  //   await request(
  //     `${props.url || location.pathname}/updateresolutionexecutors`,
  //     {
  //       method: "post",
  //       body: {
  //         id: rowData.templates?.id,
  //         rowId: rowData.executors?.id,
  //         numPP: rowData.executors?.num_pp,
  //         recordCount: data.executors.length,
  //         type,
  //       },
  //       cancelTokenKey: "updateresolutionexecutors",
  //     }
  //   );
  //   await fetchData("executors");
  //   await fetchData("templates");
  //   setSelectedRow((prev) => ({ ...prev, executors: undefined }));
  // };

  return (
    <FormProvider>
      <Space className={styles["space"]}>
        <Space size={"middle"}>
          {props.url === "/directories/resolution-templates/executors" ? (
            <>
              <Modal
                name="add"
                url="/directories/employees-and-contact-persons"
              >
                <Directories
                  url="/directories/employees-and-contact-persons"
                  form={props.form}
                />
              </Modal>
              {Object.keys(positionButtons).map((item) => (
                <Tooltip
                  title={intl(`${positionButtons[item].tooltip}-position`)}
                >
                  <Button
                    key={item}
                    className={styles["button"]}
                    disabled={disabled.edit}
                    // onClick={() => updatePosition(item)}
                  >
                    {positionButtons[item].icon}
                  </Button>
                </Tooltip>
              ))}
            </>
          ) : (props.url || location.pathname) ===
            "/directories/employees-and-contact-persons" ? (
            dropdown
          ) : (
            <Modal name="add">{formComponent}</Modal>
          )}
          {props.url !== "/directories/resolution-templates/executors" && (
            <Modal name="edit" isEdit={isEditFormComponent}>
              {isEditFormComponent ? editFormComponent : formComponent}
            </Modal>
          )}

          {location.pathname.includes("documents") ? (
            <DropdownButtons intl={intl} />
          ) : (
            <Popconfirm />
          )}
        </Space>
        {isNomenclatureOfCases && <Select url={props.url} />}
      </Space>
      {isCheckboxGroup && <CheckboxGroup url={props.url} />}
      {isRadioGroup && <RadioGroup />}
    </FormProvider>
  );
}
