import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FormInstance } from "antd";

import { MergedColumnType } from "../../../../../../ui/table/utils/types";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

export const setDocuments: Record<
  string,
  (form: FormInstance, rowData: MergedColumnType) => void
> = {
  "/directories/passing": (form, rowData) => {
    form.setFieldsValue({
      actionStage: {
        value: rowData.eta_actions_doc_id,
        label: rowData.name_actions_doc,
      },
      performer: rowData.Executors_FIO_id,
      controls: rowData.Controllers_id,
      dateOfTransferToTheContractor:
        rowData.dateN_fact && dayjs(rowData.dateN_fact, "YYYY-M-D"),
      dateOfControl:
        rowData.dateK_plan && dayjs(rowData.dateK_plan, "YYYY-M-D"),
      scheduledExecutionDate:
        rowData.date_exec && dayjs(rowData.date_exec, "YYYY-M-D"),
      controlType: {
        value: rowData.eta_TypeTerminKontrol_id,
        label: rowData.Name_TypeTerminKontrol,
      },
      paragraphNo: rowData.punkt_no,
      appendixNo: rowData.dodatok_no,
      controlNotes: rowData.prim_fakt,
      contentActionStage: rowData.text_rezolizii,
      notes: rowData.prim_rezolizii,
    });
  },
  "/directories/related-documents": (form, rowData) => {
    form.setFieldsValue({
      typesOfCommunication: rowData.eta_TypeDoc_relation_id,
      documentType: {
        value: rowData.eta_const_typeDoc_id,
        label: rowData.name_const_typeDoc,
      },
      document: rowData.bta_documents_id_parent,
      notes: rowData.note,
    });
  },
  "/directories/discussion": (form, rowData) => {
    form.setFieldsValue({
      message: rowData.send_text,
    });
  },
};
