import {
  NomenclatureOfCasesCurrent,
  NomenclatureOfCases,
  ResolutionTemplates,
  DocumentCharacteristics,
} from "./directories/document-characteristics";
import {
  HrDepartment,
  StructuralUnits,
  Positions,
  EmployeesAndContactPersons,
} from "./directories/hr-department";
import { General } from "./directories/general";
import {
  ConfiguringPageAccess,
  ButtonAccessSettings,
  ServerSettings,
  Users,
} from "./administration";

import type { CheckboxProps } from "antd";

interface FormItemsProps {
  pathname: string;
  onChange: CheckboxProps["onChange"];
}

type RoutesConfig = {
  [key: string]: React.FC<FormItemsProps>;
};

const routesConfig: RoutesConfig = {
  "/directories/nomenclature-of-cases-current": NomenclatureOfCasesCurrent,
  "/directories/nomenclature-of-cases": NomenclatureOfCases,
  "/directories/resolution-templates": ResolutionTemplates,
  "/directories/types-of-documents": DocumentCharacteristics,
  "/directories/indexes-of-documents": DocumentCharacteristics,
  "/directories/types-of-deliveries": DocumentCharacteristics,
  "/directories/types-of-communication": DocumentCharacteristics,
  "/directories/profession-classifier": HrDepartment,
  "/directories/job-categories": HrDepartment,
  "/directories/types-of-structural-unit": HrDepartment,
  "/directories/employees": EmployeesAndContactPersons,
  "/directories/structural-units": StructuralUnits,
  "/directories/positions": Positions,
  "/directories/employees-and-contact-persons": EmployeesAndContactPersons,
  "/directories/correspondents": General,
  "/administration/configuring-page-access": ConfiguringPageAccess,
  "/administration/button-access-settings": ButtonAccessSettings,
  "/administration/server-settings": ServerSettings,
  "/administration/users": Users,
};

export default function FormItems({ pathname, onChange }: FormItemsProps) {
  const Component = routesConfig[pathname];

  return Component ? (
    <Component pathname={pathname} onChange={onChange} />
  ) : null;
}
