import styles from "../../../styles.module.scss";

import { createColumn } from "../utils";
import { MergedColumnType } from "../../types";
//UI
import type { ColumnType } from "antd/es/table";
import Checkbox from "antd/lib/checkbox";

export const configuringPageAccessColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[],
  data?: MergedColumnType[]
) => [
  createColumn(
    intl("role"),
    "Role",
    undefined,
    undefined,
    (a, b) => a.Role?.localeCompare(b.Role),
    undefined,
    true,
    data
  ),
  createColumn(
    intl("pages"),
    "Pages",
    undefined,
    undefined,
    (a, b) => a.Pages?.localeCompare(b.Pages),
    undefined,
    true,
    data
  ),
  {
    title: intl("access"),
    dataIndex: "Access",
    key: "Access",
    align: "center" as const,
    width: 200,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.Access}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.Access === value,
  },
];

export const buttonAccessSettingsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[],
  data?: MergedColumnType[]
) => [
  createColumn(
    intl("role"),
    "Role",
    650,
    undefined,
    (a, b) => a.Role?.localeCompare(b.Role),
    undefined,
    true,
    data
  ),
  createColumn(
    intl("pages"),
    "Pages",
    undefined,
    undefined,
    (a, b) => a.Pages?.localeCompare(b.Pages),
    undefined,
    true,
    data
  ),
  createColumn(
    intl("buttons"),
    "Buttons",
    undefined,
    undefined,
    (a, b) => a.Buttons?.localeCompare(b.Buttons),
    undefined,
    true,
    data
  ),
  {
    title: intl("access"),
    dataIndex: "Access",
    key: "Access",
    align: "center" as const,
    width: 150,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.Access}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.Access === value,
  },
];

export const serverSettingsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[],
  data?: MergedColumnType[]
) => [
  createColumn(
    intl("servername"),
    "ServerName",
    undefined,
    undefined,
    (a, b) => a.ServerName?.localeCompare(b.ServerName),
    undefined,
    true,
    data
  ),
  createColumn(
    intl("servercode"),
    "ServerCode",
    undefined,
    undefined,
    (a, b) => a.ServerCode?.localeCompare(b.ServerCode),
    undefined,
    true,
    data
  ),
  createColumn(
    intl("imaphost"),
    "ImapHost",
    undefined,
    undefined,
    (a, b) => a.ImapHost?.localeCompare(b.ImapHost),
    undefined,
    true,
    data
  ),
  createColumn(
    intl("imapport"),
    "ImapPort",
    undefined,
    undefined,
    (a, b) => a.ImapPort?.localeCompare(b.ImapPort),
    undefined,
    true,
    data
  ),
];

export const usersColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  createColumn(
    intl("login-email"),
    "email",
    undefined,
    getColumnSearchProps,
    (a, b) => a.email?.localeCompare(b.email)
  ),
  createColumn(
    intl("last-name"),
    "LastName",
    200,
    getColumnSearchProps,
    (a, b) => a.LastName?.localeCompare(b.LastName)
  ),
  createColumn(
    intl("first-name"),
    "FirstName",
    200,
    getColumnSearchProps,
    (a, b) => a.FirstName?.localeCompare(b.FirstName)
  ),
  createColumn(
    intl("middle-name"),
    "MiddleName",
    200,
    getColumnSearchProps,
    (a, b) => a.MiddleName?.localeCompare(b.MiddleName)
  ),
  createColumn(
    intl("sed-user"),
    "UserName",
    350,
    getColumnSearchProps,
    (a, b) => a.UserName?.localeCompare(b.UserName)
  ),
  createColumn(
    intl("role"),
    "Name_prav",
    undefined,
    getColumnSearchProps,
    (a, b) => a.Name_prav?.localeCompare(b.Name_prav)
  ),
  {
    title: intl("access"),
    dataIndex: "del",
    key: "del",
    align: "center" as const,
    width: 100,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.del}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.del === value,
  },
  {
    title: intl("deleted"),
    dataIndex: "isDelUser",
    key: "isDelUser",
    align: "center" as const,
    width: 100,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.isDelUser}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.isDelUser === value,
  },
];

export const documentDisplayOptionsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[],
  data?: MergedColumnType[]
) => [
  createColumn(
    intl("parameters"),
    "NameTypeRec",
    200,
    getColumnSearchProps,
    (a, b) => a.NameTypeRec?.localeCompare(b.NameTypeRec)
  ),
];

export const documentDisplayOptionsAccessColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[],
  data?: MergedColumnType[],
  visible?: boolean,
  onChange?: (id: number, e: boolean) => Promise<void>
) => [
  {
    title: intl("access"),
    dataIndex: "Value",
    key: "Value",
    align: "center" as const,
    width: 100,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.Value}
        onChange={(e) => onChange?.(record.id, e.target.checked)}
        // disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.isDelUser === value,
  },
  createColumn(intl("role"), "Name", undefined, getColumnSearchProps, (a, b) =>
    a.Name?.localeCompare(b.Name)
  ),
];
