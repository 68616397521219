import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { DirectoriesFormProps } from "./types";
import { request } from "../../../services";
import { ModalContext } from "../../../contexts/modal/index.context";
import { FormContext } from "../../../contexts/form/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
//Utils
import { setFieldValuesFunctions } from "./utils/set-field-values";
import FormItems from "./utils/form-items";
//UI
import Form from "antd/lib/form";
import type { CheckboxProps } from "antd";

export default function DirectoriesForm(props: DirectoriesFormProps) {
  const location = useLocation();
  const { form } = useContext(FormContext);
  const { sessionData, setSessionData } = useContext(SessionContext);
  const [checkboxes, setCheckboxes] = useState<string[]>([]);
  const { activeModal, disabled, setDisabled, setRefresh } =
    useContext(ModalContext);

  useEffect(() => {
    if (activeModal === "edit" && sessionData) {
      const setFieldValues =
        setFieldValuesFunctions[props.url || location.pathname];
      setFieldValues && setFieldValues(form, sessionData);
    } else {
      form.resetFields();
    }
  }, [activeModal, sessionData]);

  const onChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setCheckboxes([...checkboxes, e.target.value]);
    } else {
      setCheckboxes(checkboxes.filter((item) => item !== e.target.value));
    }
  };

  const getURL = (
    baseURL: string,
    activeModal: string | null,
    sessionData: any
  ) => {
    if (baseURL !== "/directories/employees-and-contact-persons") {
      return baseURL;
    }
    if (activeModal === "edit") {
      return `${baseURL}/${
        sessionData.is_clerk ? "employee" : "contact-person"
      }`;
    }
    return `${baseURL}/${activeModal}`;
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);

    let response;
    let url = getURL(
      `${props.url || location.pathname}`,
      activeModal,
      sessionData
    );
    values.checkboxes = checkboxes.join(",");

    if (activeModal === "edit") {
      values.id = sessionData.id;
    }
    const endpoint =
      activeModal === "edit" ? "updatedatatable" : "adddatatable";
    response = await request(`${url}/${endpoint}`, {
      method: "post",
      body: {
        documentId: props.id ?? sessionData?.bta_documents_id,
        page: props.url?.split("/").pop(),
        ...values,
      },
      cancelTokenKey: endpoint,
    });
    activeModal !== "edit" && setDisabled({ ...disabled, edit: false });

    response.success && setSessionData(response.data[0]);
    form.resetFields();
    setRefresh(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      className={styles["form"]}
      name="directories"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{ webVersion: true }}
    >
      <FormItems
        pathname={props.url || location.pathname}
        onChange={onChange}
      />
    </Form>
  );
}
