import styles from "./styles.module.scss";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { PopconfirmProps } from "./types";
import { MergedColumnType } from "../table/utils/types";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { ModalContext } from "../../../contexts/modal/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
import { request } from "../../../services";
//UI
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import message from "antd/lib/message";
//Icons
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

export default function PopconfirmComponent(props: PopconfirmProps) {
  const [loading, setLoading] = useState(false);
  const { setRefresh } = useContext(ModalContext);
  const { intl } = useContext(TranslationContext);
  const { sessionData } = useContext(SessionContext);
  const { setRefreshFileEditor } = useContext(ModalContext);
  const location = useLocation();

  const info = () => {
    message.error(intl("data-is-not-deleted"));
  };

  const handleOk = async () => {
    setLoading(true);
    const success = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/deletefiles`,
        {
          method: "post",
          body: {
            ids: props.filesId,
            documentId: sessionData?.bta_documents_id,
            page: location.pathname.split("/").pop(),
          },
          cancelTokenKey: "deletefiles",
        }
      )
    )?.success;
    if (!success) {
      info();
    }
    setRefreshFileEditor(true);
    setLoading(false);
  };

  return (
    <Popconfirm
      title={intl("question-delete-entry")}
      onConfirm={handleOk}
      okButtonProps={{ loading, className: styles["popconfirm"] }}
      okText={intl("ok")}
    >
      <Button
        // className={`${styles["button"]} ${
        //   !disabled.edit && styles["custom-button"]
        // }`}
        className={styles["button"]}
        disabled={props.disabled}
        icon={<DeleteOutlined />}
      >
        {/* {intl(props.name || "remove")} */}
      </Button>
    </Popconfirm>
  );
}
