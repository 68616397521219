import { createContext, useState } from "react";
import { ModalContextValue, ModalProviderProps } from "./types";

export const ModalContext = createContext<ModalContextValue>({
  activeModal: null,
  setActiveModal: () => {},
  disabled: { add: false, edit: true },
  setDisabled: () => {},
  refresh: false,
  setRefresh: () => {},
  refreshFileEditor: false,
  setRefreshFileEditor: () => {},
});

export function ModalProvider({ children }: ModalProviderProps) {
  const [activeModal, setActiveModal] = useState<
    | "add"
    | "edit"
    | "contact-person"
    | "employee"
    | "add-an-action-for-one-performer"
    | "add-an-action-from-a-template"
    | null
  >(null);
  const [disabled, setDisabled] = useState({
    add: false,
    edit: true,
  });
  const [refresh, setRefresh] = useState(false);
  const [refreshFileEditor, setRefreshFileEditor] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        activeModal,
        setActiveModal,
        disabled,
        setDisabled,
        refresh,
        setRefresh,
        refreshFileEditor,
        setRefreshFileEditor,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
