import { BrowserRouter, Routes, Route } from "react-router-dom";

// import MainLayout from "./hoc/main-layout";
import Login from "./pages/auth/login";
import PasswordRecovery from "./pages/auth/password-recovery";
import Profile from "./pages/profile";
import Home from "./pages/home";
import Information from "./pages/information";

import Documents from "./pages/documents";

import Directories from "./pages/directories";
import DirectoriesPage from "./pages/directories/page";

import Administration from "./pages/administration";
import AdministrationPage from "./pages/administration/page";

const routes = [
  {
    path: "/auth",
    element: <Login />,
  },
  {
    path: "/auth/password-recovery",
    element: <PasswordRecovery />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/information",
    element: <Information />,
  },

  {
    path: "/documents/incoming",
    element: <Documents key="incoming" />,
  },
  {
    path: "/documents/outcoming",
    element: <Documents key="outcoming" />,
  },
  {
    path: "/documents/internal",
    element: <Documents key="internal" />,
  },
  {
    path: "/documents/resolutions",
    element: <Documents key="resolutions" />,
  },
  {
    path: "/documents/for-signature",
    element: <Documents key="for-signature" />,
  },
  {
    path: "/documents/list-of-documents",
    element: <Documents key="list-of-documents" />,
  },

  {
    path: "/directories",
    element: <Directories />,
  },

  {
    path: "/directories/nomenclature-of-cases-current",
    element: <DirectoriesPage key="nomenclature-of-cases-current" />,
  },
  {
    path: "/directories/nomenclature-of-cases",
    element: <DirectoriesPage key="nomenclature-of-cases" />,
  },
  {
    path: "/directories/resolution-templates",
    element: <DirectoriesPage key="resolution-templates" />,
  },
  {
    path: "/directories/types-of-documents",
    element: <DirectoriesPage key="types-of-documents" />,
  },
  {
    path: "/directories/indexes-of-documents",
    element: <DirectoriesPage key="indexes-of-documents" />,
  },
  {
    path: "/directories/types-of-deliveries",
    element: <DirectoriesPage key="types-of-deliveries" />,
  },
  {
    path: "/directories/types-of-communication",
    element: <DirectoriesPage key="types-of-communication" />,
  },

  {
    path: "/directories/profession-classifier",
    element: <DirectoriesPage key="profession-classifier" />,
  },
  {
    path: "/directories/job-categories",
    element: <DirectoriesPage key="job-categories" />,
  },
  {
    path: "/directories/types-of-structural-unit",
    element: <DirectoriesPage key="types-of-structural-unit" />,
  },
  {
    path: "/directories/employees",
    element: <DirectoriesPage key="employees" />,
  },
  {
    path: "/directories/structural-units",
    element: <DirectoriesPage key="structural-units" />,
  },
  {
    path: "/directories/positions",
    element: <DirectoriesPage key="positions" />,
  },
  {
    path: "/directories/employees-and-contact-persons",
    element: <DirectoriesPage key="employees-and-contact-persons" />,
  },

  {
    path: "/directories/correspondents",
    element: <DirectoriesPage key="correspondents" />,
  },

  {
    path: "/administration",
    element: <Administration />,
  },

  {
    path: "/administration/configuring-page-access",
    element: <DirectoriesPage key="configuring-page-access" />,
  },
  {
    path: "/administration/button-access-settings",
    element: <DirectoriesPage key="button-access-settings" />,
  },
  {
    path: "/administration/server-settings",
    element: <DirectoriesPage key="server-settings" />,
  },
  {
    path: "/administration/users",
    element: <DirectoriesPage key="users" />,
  },
  {
    path: "/administration/document-display-options",
    element: <AdministrationPage key="document-display-options" />,
  },
];

export default function Router() {
  return (
    <BrowserRouter>
      {/* <MainLayout activeLink={window.location.pathname}> */}
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Routes>
      {/* </MainLayout> */}
    </BrowserRouter>
  );
}
