import styles from "./styles.module.scss";
import { connect } from "react-redux";

import MainLayout from "../../../hoc/main-layout";
import * as Actions from "../../../store/actions";
import { PropsTypes } from "../../types";
import { StateTypes } from "../../../store/types";
import { TranslationProvider } from "../../../contexts/translation/index.context";
import { TableProvider } from "../../../contexts/table/index.context";
//Components
import Administration from "../../../components/administration";
import { SessionProvider } from "../../../contexts/session/index.context";

function AdministrationPage(props: PropsTypes) {
  return (
    <MainLayout activeLink={window.location.pathname}>
      <TranslationProvider lang={props.lang}>
        <TableProvider>
          <SessionProvider>
            <Administration />
          </SessionProvider>
        </TableProvider>
      </TranslationProvider>
    </MainLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message: string) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationPage);
