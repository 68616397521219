import styles from "./styles.module.scss";
import "@cyntler/react-doc-viewer/dist/index.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, Page, pdfjs } from "react-pdf";

import { FileEditorComponentProps } from "./types";
import { FilesType } from "../../forms/documents/types";
import { request } from "../../../services";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
//Components
import FileMenu from "../file-menu";
import Popconfirm from "../popconfirm-files";
//UI
import Space from "antd/lib/space";
import Checkbox from "antd/lib/checkbox";
import Card from "antd/lib/card";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
//Icons
import FilePdfOutlined from "@ant-design/icons/FilePdfOutlined";
import FileWordOutlined from "@ant-design/icons/FileWordOutlined";
import FileExcelOutlined from "@ant-design/icons/FileExcelOutlined";
import FileJpgOutlined from "@ant-design/icons/FileJpgOutlined";
import UpOutlined from "@ant-design/icons/UpOutlined";
import DownOutlined from "@ant-design/icons/DownOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import KeyOutlined from "@ant-design/icons/KeyOutlined";
import FormOutlined from "@ant-design/icons/FormOutlined";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function FileEditorComponent(props: FileEditorComponentProps) {
  const { files } = props;
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { sessionData } = useContext(SessionContext);
  // const [file, setFile] = useState<FilesType[]>([]);
  const [file, setFile] = useState<FilesType | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [editInfoBtnDisabled, setEditInfoBtnDisabled] = useState(true);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollContainer = (direction: "up" | "down") => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollAmount = direction === "up" ? -80 : 80;
      container.scrollBy({
        top: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const onChange = (id: number) => (e: CheckboxChangeEvent) => {
    setSelectedFiles((prev) =>
      e.target.checked ? [...prev, id] : prev.filter((fileId) => fileId !== id)
    );
  };

  const handleSelectAll = () => {
    setSelectedFiles(files.map((file) => file.id));
  };

  const handleGetFile = (id: number) => {
    fetchFile(id);
    setEditInfoBtnDisabled(false);
  };

  const fetchFile = async (fileId: number) => {
    setFile(null);
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getfiles`,
        {
          method: "post",
          body: { id: sessionData.bta_documents_id, fileId },
          cancelTokenKey: "getfiles",
        }
      )
    )?.content;
    setFile(content?.[0] || null);
  };

  const bufferToBlob = (
    buffer: { type: string; data: number[] },
    mimeType: string
  ) => {
    const byteArray = new Uint8Array(buffer.data);
    return new Blob([byteArray], { type: mimeType });
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const fileIconMap = {
    ".pdf": FilePdfOutlined,
    ".doc": FileWordOutlined,
    ".docx": FileWordOutlined,
    ".jpg": FileJpgOutlined,
    ".jpeg": FileJpgOutlined,
    ".png": FileJpgOutlined,
    ".xlsx": FileExcelOutlined,
  };

  const mimeTypeMap: { [key: string]: string } = {
    ".txt": "text/plain",
    ".pdf": "application/pdf",
    ".doc": "application/msword",
    ".docx":
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".xls": "application/vnd.ms-excel",
    ".xlsx":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".jpg": "image/jpg",
    ".jpeg": "image/jpeg",
    ".png": "image/png",
    ".bmp": "image/bmp",
  };

  const renderFile = () => {
    if (!file) {
      return null;
    }

    const { name_file_view, fileBody, fileExtention } = file;
    const fileBlob = new Blob([new Uint8Array(fileBody.data)]);
    const fileUrl = URL.createObjectURL(fileBlob);

    const docs = [
      {
        uri: fileUrl,
        fileName: name_file_view,
        fileType: fileExtention.replace(".", ""),
      },
    ];

    const config = {
      pdfZoom: {
        defaultZoom: 4.5,
        zoomJump: 1,
      },
    };

    return (
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={config}
      />
    );
  };

  return (
    <Space direction="vertical">
      <Space style={{ padding: 10 }}>
        <FileMenu file={file} disabled={editInfoBtnDisabled} />
        <Popconfirm
          filesId={selectedFiles}
          disabled={selectedFiles.length === 0}
        />
        <Button
          icon={<FormOutlined /* style={{ fontSize: "24px" }} */ />}
          style={{ border: "none" }}
          disabled={selectedFiles.length === 0}
        />
      </Space>
      <Space align="start">
        <div
          style={{
            width: "max-content",
            height: "calc(100vh - 150px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <UpOutlined
            onClick={() => scrollContainer("up")}
            style={{
              fontSize: "12px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
          />
          <div
            ref={containerRef}
            style={{
              // width: "200px",
              height: "calc(100vh - 150px)",
              overflowY: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {files.map((file, index) => {
              const Icon =
                fileIconMap[file.fileExtention as keyof typeof fileIconMap];
              return (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <Card
                    hoverable
                    size="small"
                    style={{
                      width: "max-content",
                      textAlign: "center",
                      padding: "5px",
                    }}
                    onClick={() => handleGetFile(file.id)}
                  >
                    <Space align="start">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <CheckCircleOutlined
                          style={{
                            fontSize: "14px",
                            marginBottom: "8px",
                            color: file.isOriginal ? "#077e96" : "#e4e3e6",
                          }}
                        />
                        <KeyOutlined
                          style={{
                            fontSize: "14px",
                            color: file.isSign ? "#6e0fb6" : "#e4e3e6",
                          }}
                        />
                        <Checkbox
                          checked={selectedFiles.includes(file.id)}
                          onChange={onChange(file.id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Icon
                          style={{ fontSize: "32px", marginBottom: "8px" }}
                        />
                        <Tooltip title={file.name_file_view}>
                          <div>{`${file.name_file_view.slice(0, 5)}...`}</div>
                        </Tooltip>
                      </div>
                    </Space>
                  </Card>
                </div>
              );
            })}
          </div>
          <DownOutlined
            onClick={() => scrollContainer("down")}
            style={{
              fontSize: "12px",
              cursor: "pointer",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <Button
            className={styles["file-list-button"]}
            onClick={handleSelectAll}
          >
            {intl("select-all-files")}
          </Button>
          <Button className={styles["file-list-button"]}>
            {intl("save-files")}
          </Button>
        </div>
        <div>
          {renderFile()}
          {/* {file?.fileExtention === ".pdf" && (         
            <div>
              <Document
                file={bufferToBlob(file.fileBody, "application/pdf")}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button disabled={pageNumber <= 1} onClick={previousPage}>
                  <LeftOutlined />
                </Button>
                <p>
                  {pageNumber} of {numPages}
                </p>
                <Button disabled={pageNumber >= numPages} onClick={nextPage}>
                  <RightOutlined />
                </Button>
              </div>
            </div>
          )} */}
        </div>
      </Space>
    </Space>
  );
}
