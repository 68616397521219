import styles from "./styles.module.scss";
import { useState, createElement, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as Actions from "../../store/actions";
import { translate, availableLanguages } from "../../translation";
import { MainPropsTypes, ParentItemsTypes } from "../types";
import { StateTypes } from "../../store/types";
import { decryptData } from "../../services/encryption";
import User from "../../services/user";
import logo from "../../assets/logo-white.png";
import Splitter from "../../components/ui/splitter";
//UI
import ConfigProvider from "antd/lib/config-provider";
import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
import Breadcrumb from "antd/lib/breadcrumb";
import Dropdown from "antd/lib/dropdown";
import Space from "antd/lib/space";
import type { MenuProps } from "antd/es/menu/menu";
//Icons
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import FileDoneOutlined from "@ant-design/icons/FileDoneOutlined";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined";
import BookOutlined from "@ant-design/icons/BookOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";
import MenuUnfoldOutlined from "@ant-design/icons/MenuUnfoldOutlined";
import MenuFoldOutlined from "@ant-design/icons/MenuFoldOutlined";
import GlobalOutlined from "@ant-design/icons/GlobalOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import DashOutlined from "@ant-design/icons/DashOutlined";
//Locale
import ukUA from "antd/locale/uk_UA";

const { Header, Sider, Content, Footer } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

function MainLayout(props: MainPropsTypes) {
  const [language, setLanguage] = useState("Українська");
  const [collapsed, setCollapsed] = useState(false);
  const [parent, setParent] = useState<string[]>([]);
  const [parentItems, setParentItems] = useState<ParentItemsTypes[]>([]);
  const [openKeys, setOpenKeys] = useState(
    JSON.parse(localStorage.getItem("open-keys") || "null") || ["sub1"]
  );
  const currentUser = User.decode();
  const logoStyles = [styles["logo"]];
  if (collapsed) {
    logoStyles.push(styles["collapsed"]);
  }

  const intl = (selector: string) => {
    return translate(selector, props.lang ?? "uk");
  };

  useEffect(() => {
    if (!currentUser) window.location.href = "/auth";
    if (parent.length === 0 || parentItems.length === 0) {
      getParentRoutes();
    }
    // fetchTableData();
  }, [currentUser, parent, parentItems]);

  const getParentRoutes = async () => {
    const accesses = decryptData();
    if (accesses) {
      setParent(accesses.map((i: { parentRoute: string }) => i?.parentRoute));
      setParentItems(accesses);
    } else {
      window.location.href = "/auth";
    }
  };

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    title?: string
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      title,
    } as MenuItem;
  };

  const docsObjs = parentItems.filter((obj) => obj.parentRoute === "docs");
  const dynamicDocsObjs = parentItems.filter(
    (obj) => obj.parentRoute === "dynamic-docs"
  );
  let startDocsId = 2;
  const menuItems: MenuItem[] = [
    ...(parent?.includes("info")
      ? [
          getItem(
            <Link to="/information">{intl("information")}</Link>,
            "1",
            <InfoCircleOutlined />,
            undefined,
            intl("information")
          ),
        ]
      : []),
    ...(parent?.includes("docs") || parent?.includes("dynamic-docs")
      ? [
          getItem(intl("documents"), "sub1", <FileDoneOutlined />, [
            ...docsObjs.map((obj, index) =>
              getItem(
                <Link key={index} to={obj.route}>
                  {obj.name}
                </Link>,
                // (startDocsId + index).toString()
                obj.idRoute,
                undefined,
                undefined,
                obj.name
              )
            ),
            ...dynamicDocsObjs.map((obj, index) =>
              getItem(
                <Link key={index} to={obj.route}>
                  {obj.name}
                </Link>,
                (startDocsId + docsObjs.length + index).toString(),
                undefined,
                undefined,
                obj.name
              )
            ),
          ]),
        ]
      : []),
    ...(parent?.includes("stats")
      ? [
          getItem(
            <Link to="/statistics">{intl("statistics")}</Link>,
            "20",
            <BarChartOutlined />,
            undefined,
            intl("statistics")
          ),
        ]
      : []),
    ...(parent?.includes("dirs")
      ? [
          getItem(
            <Link to="/directories">{intl("directories")}</Link>,
            "12",
            <BookOutlined />,
            undefined,
            intl("directories")
          ),
        ]
      : []),
    ...(parent?.includes("admin")
      ? [
          getItem(
            <Link to="/administration">{intl("administration")}</Link>,
            "7",
            <SettingOutlined />,
            undefined,
            intl("administration")
          ),
        ]
      : []),
  ];

  const routesObj = {
    "/profile": {
      id: [],
      title: intl("profile"),
      breadcrump: null,
    },
    "/information": {
      id: ["1"],
      title: intl("information"),
      breadcrump: null,
    },
    "/documents/incoming": {
      id: ["2"],
      title: intl("documents"),
      breadcrump: intl("incoming"),
    },
    "/documents/outcoming": {
      id: ["3"],
      title: intl("documents"),
      breadcrump: intl("outcoming"),
    },
    "/documents/internal": {
      id: ["4"],
      title: intl("documents"),
      breadcrump: intl("internal"),
    },
    // "/documents/internalview": {
    //   id: ["4"],
    //   title: intl("documents"),
    //   breadcrump: intl("internal"),
    // },
    "/documents/resolutions": {
      id: ["5"],
      title: intl("documents"),
      breadcrump: intl("resolutions"),
    },
    "/documents/for-signature": {
      id: ["6"],
      title: intl("documents"),
      breadcrump: intl("for-signature"),
    },
    "/directories": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: null,
    },
    "/directories/nomenclature-of-cases-current": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("nomenclature-of-cases-current"),
    },
    "/directories/nomenclature-of-cases": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("nomenclature-of-cases"),
    },
    "/directories/types-of-documents": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("types-of-documents"),
    },
    "/directories/indexes-of-documents": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("indexes-of-documents"),
    },
    "/directories/types-of-deliveries": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("types-of-deliveries"),
    },
    "/directories/types-of-communication": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("types-of-communication"),
    },
    "/directories/profession-classifier": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("profession-classifier"),
    },
    "/directories/job-categories": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("job-categories"),
    },
    "/directories/types-of-structural-unit": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("types-of-structural-unit"),
    },
    "/directories/employees": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("employees"),
    },
    "/directories/structural-units": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("structural-units"),
    },
    "/directories/positions": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("positions"),
    },
    "/directories/correspondents": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("correspondents"),
    },
    "/directories/employees-and-contact-persons": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("employees-and-contact-persons"),
    },
    "/directories/resolution-templates": {
      id: ["12"],
      title: intl("directories"),
      breadcrump: intl("resolution-templates"),
    },
    "/administration": {
      id: ["7"],
      title: intl("administration"),
      breadcrump: null,
    },
    "/administration/configuring-page-access": {
      id: ["7"],
      title: intl("administration"),
      breadcrump: intl("configuring-page-access"),
    },
    "/administration/button-access-settings": {
      id: ["7"],
      title: intl("administration"),
      breadcrump: intl("button-access-settings"),
    },
    "/administration/server-settings": {
      id: ["7"],
      title: intl("administration"),
      breadcrump: intl("server-settings"),
    },
    "/administration/users": {
      id: ["7"],
      title: intl("administration"),
      breadcrump: intl("users"),
    },
    "/administration/document-display-options": {
      id: ["7"],
      title: intl("administration"),
      breadcrump: intl("document-display-options"),
    },
    // "/footer/privacy-policy": {
    //   id: ["146"],
    //   title: intl("privacy-policy"),
    //   breadcrump: intl("privacy-policy"),
    // },
    // "/footer/terms-of-use": {
    //   id: ["147"],
    //   title: intl("terms-of-use"),
    //   breadcrump: intl("terms-of-use"),
    // },
    // "/footer/support": {
    //   id: ["148"],
    //   title: intl("support"),
    //   breadcrump: intl("support"),
    // },
  };

  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys(() => {
      localStorage.setItem("open-keys", JSON.stringify(openKeys));
      return openKeys;
    });
  };

  const resolveActiveSelectedMenu = (path: string) => {
    // if (path.includes(":page")) return `/cabinet/${page}`;
    // else return routesObj[path]?.id || ["0"];
    return routesObj[path as keyof typeof routesObj]?.id || ["0"];
  };

  const activeLink = resolveActiveSelectedMenu(props.activeLink);

  const resolveTitle = (path: string) => {
    return routesObj[path as keyof typeof routesObj]?.title || null;
  };

  const resolveBreadcrump = (path: string) => {
    return routesObj[path as keyof typeof routesObj]?.breadcrump || null;
  };

  const breadcrumbItems = [
    {
      title: intl("cabinet"),
    },
    {
      title: props.activeLink.includes("/documents") ? (
        resolveTitle(props.activeLink)
      ) : (
        <a href={`/${props.activeLink.split("/")[1]}`}>
          {resolveTitle(props.activeLink)}
        </a>
      ),
    },
    // {
    //   title: (
    //     <a href={props.activeLink}>{resolveBreadcrump(props.activeLink)}</a>
    //   ),
    // },
  ];

  resolveBreadcrump(props.activeLink) &&
    breadcrumbItems.push({
      title: (
        <a href={props.activeLink}>{resolveBreadcrump(props.activeLink)}</a>
      ),
    });

  const languageItems: MenuProps["items"] = availableLanguages;
  const userItems: MenuProps["items"] = [
    {
      key: "1",
      label: intl("profile"),
      icon: <UserOutlined />,
    },
    {
      type: "divider",
    },
    {
      key: "2",
      danger: true,
      label: intl("logout"),
      icon: <LogoutOutlined />,
    },
  ];
  const footerItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link to="/footer/privacy-policy" className={styles["link"]}>
          {intl("privacy-policy")}
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/footer/terms-of-use" className={styles["link"]}>
          {intl("terms-of-use")}
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/footer/support" className={styles["link"]}>
          {intl("support")}
        </Link>
      ),
    },
  ];

  const onClickLanguage: MenuProps["onClick"] = ({ key }) => {
    const language = availableLanguages.find(
      (lang) => lang.key === Number(key)
    );
    if (language) {
      props.changeLanguage(language.code);
      setLanguage(language.label);
    }
  };

  const onClickUser: MenuProps["onClick"] = ({ key }) => {
    if (Number(key) === 1) {
      window.location.href = "/profile";
    } else {
      User.unset();
      window.location.href = "/auth";
    }
  };

  return (
    <Layout className={styles["main-layout"]}>
      <Splitter
        collapsed={collapsed}
        leftComponent={
          <Sider
            className={styles["sider"]}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div className={logoStyles.join(" ")}>
              <img src={logo} alt="Logo" />
            </div>
            <Menu
              className={styles["menu"]}
              mode="inline"
              defaultSelectedKeys={activeLink}
              defaultOpenKeys={openKeys}
              onOpenChange={onOpenChange}
              items={menuItems}
              // onClick={() => sessionStorage.clear()}
            />
          </Sider>
        }
        rightComponent={
          <Layout className={styles["right-layout"]}>
            <Header className={styles["header"]}>
              <Space size={"large"}>
                {createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: styles["trigger"],
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
                <Breadcrumb
                  className={styles["breadcrumb"]}
                  items={breadcrumbItems}
                />
              </Space>
              <Space size={"middle"}>
                <Dropdown
                  className={styles["dropdown"]}
                  menu={{ items: languageItems, onClick: onClickLanguage }}
                >
                  <a href="." onClick={(e) => e.preventDefault()}>
                    <Space>
                      <GlobalOutlined />
                      {language}
                      <CaretDownOutlined />
                    </Space>
                  </a>
                </Dropdown>
                <Dropdown
                  className={styles["dropdown"]}
                  menu={{ items: userItems, onClick: onClickUser }}
                >
                  <a href="." onClick={(e) => e.preventDefault()}>
                    <Space>
                      {currentUser?.login}
                      <CaretDownOutlined />
                    </Space>
                  </a>
                </Dropdown>
                <Dropdown
                  className={styles["dropdown"]}
                  menu={{ items: footerItems /* onClick: onClickUser */ }}
                  trigger={["click"]}
                >
                  <a href="." onClick={(e) => e.preventDefault()}>
                    <DashOutlined />
                  </a>
                </Dropdown>
              </Space>
            </Header>
            <Content className={styles["content"]}>
              <ConfigProvider locale={ukUA}>{props.children}</ConfigProvider>
            </Content>
            {/* <Footer className={styles["footer"]}>
              <Link to="/footer/privacy-policy" className={styles["link"]}>
                {intl("privacy-policy")}
              </Link>
              <Link to="/footer/terms-of-use" className={styles["link"]}>
                {intl("terms-of-use")}
              </Link>
              <Link to="/footer/support" className={styles["link"]}>
                {intl("support")}
              </Link>
            </Footer> */}
          </Layout>
        }
      />
    </Layout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
